import { useState, useContext } from "react";
import { XCircleIcon } from '@heroicons/react/20/solid';
import { ItemStatusPill } from '../Utils/ui-components/ItemStatusPill';
import { ReactComponent as Spinner } from '../Utils/ui-components/Icons/Spinner.svg';
import { ReactComponent as CameraPlus } from '../Utils/ui-components/Icons/CameraPlus.svg';
import { NotificationContext } from '../Utils/contexts/NotificationContext';
import Confirmation from '../Utils/Dialog';
import axios from 'axios';

export const ItemCard = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const token = localStorage.getItem('jwtToken');
  const { showNotification } = useContext(NotificationContext);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  const cancelReserve = (id) => {
    props.cancelReserve(id);
  }

  const cancelBuy = (id) => {
    props.cancelBuy(id);
  }

  const uploadImage = () => {
    props.setCurrentItem(props.data);
    props.setOpenPhotoModal(true);
  }

  const deleteImage = () => {
    
    api
      .post(
        `/api/items/update/image/${props.data.uuid}`,
        {
          image: null,
        },
        { headers: { Authorization: `${token}` } }
      )
      .then((res) => {
        if (res.status === 200) {
          showNotification('Imaginea a fost stearsa.', 'success');
          deleteFile(props.data.image);
        } else {
          showNotification('Imaginea nu a putut fi stearsa 1.', 'error');
        }
      })
      .catch((err) => {
        showNotification('Imaginea nu a putut fi stearsa 2.', 'error');
      });
  }

  const deleteFile = file => {
    if(file) {
      file = file.match(/\/([^\/]+)(?=\.\w+$)/);
      if(file && file[1]) {
        file = file[1];
      }
      api.post(`/api/files/delete/${file}`,
      null,
      { headers: { Authorization: `${token}` } })
      .then((res) => {
        if (res.status === 200) {
          props.updateItemsInfo(props.data.uuid, 'image', null);
        } else {
          //console.log('ERROR STATUS');
        }
      })
      .catch((err) => {
        //console.log('CAUGHT ERROR');
      });
    } 
    
  }

  const confirmDeleteImage = () => {
    setOpenModal(true);
  }

  return (
    <>    
      <Confirmation
        executeAction={deleteImage}
        openModal={openModal}
        setOpenModal={setOpenModal}
        title="Sterge imaginea"
        warning='Esti sigur ca vrei sa stergi imaginea?'
        labels={{
          confirm: "Da, sterge.",
          cancel: "Inchide"
        }}
      />
      <div
        key="item-{`${props.data.id}`}"
        className="socialButtons text-white relative before:rounded-[15px] items-center justify-center before:pointer-events-none before:absolute before:block before:h-full before:w-full before:top-0 before:left-0 before:content-[''] before:bg-white/70 before:mix-blend-soft-light p-[10px] rounded-[15px] items-start my-2 flex justify-between rounded-lg px-3 py-4"
      >
        {props.data.image && 
          <>
            <div className="w-1/5 mr-2">
              <img
                alt={props.data.name}
                className="w-full rounded-lg"
                src={`${props.data.image}`}
              />
              <div className="w-[24px] absolute left-[0px] top-[0px]">
                <XCircleIcon onClick={confirmDeleteImage} className="fill-[red]" />
              </div>
            </div>
          </>
        }
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <div className="text-sm">
              <p className="font-medium  text-white" id="headlessui-label-:R5mm:">
                {props.data.name}
              </p>
              <span className="inline text-sky-100">
                <span>
                  {props.data.url && (
                    <a href={props.data.url} target="_blank">{props.data.website}</a>
                  )}
                </span>
              </span>
              <p>
                <ItemStatusPill cancelReserve={() => {cancelReserve(props.data.uuid)}} cancelBuy={() => {cancelBuy(props.data.uuid)}} reserved={!props.data.active} bought={!props.data.available} />
              </p>
            </div>
          </div>
          <div className="shrink-0 text-white"></div>
        </div>
        <div className="mr-[5px] text-[1.5em]">
          <CameraPlus onClick={uploadImage} />
        </div>
        <div>
          {(!props.isDeleting || !isDeleting) && 
          
            <XCircleIcon
              onClick={() => {
                setIsDeleting(true);
                props.deleteItem(props.data.id);
              }}
              data-testid="test-delete-icon"
              className="cursor-pointer mx-1 h-7 w-7 text-grey-500 hover:text-red-400"
              aria-hidden="true"
            />
          }
          {isDeleting && props.isDeleting &&
          
          <div className="spinner"><Spinner /></div>
        }
        </div>
      </div>
    </>
  );
};

//@TODO allow for uniqueURL to not be required and generate uniqueURL on the backend
//@TODO come up with a strategy for hints and helps - question marks popovers next to the input fields
//@TODO check why error message is empty - cause a server side error and see what happens
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {useEscapeKey} from './Utils/hooks/useEscapeKey';
import { ReactComponent as AddListIcon } from './Utils/ui-components/Icons/AddList.svg';
import { ReactComponent as CloseSlider } from './Utils/ui-components/Icons/CloseAddList.svg';
import { TextInput } from './Utils/ui-components/TextInput';
import { SelectInput } from './Utils/ui-components/SelectInput';
import { ListsContext } from './Utils/contexts/ListsContext'
import CheckAvailability from './Utils/CheckAvailability';
import axios from 'axios';
import { eventTypes } from '../Utils/constants';
import { NotificationContext } from './Utils/contexts/NotificationContext';
import { FormButton } from './Utils/ui-components/FormButton';
import { Switch } from '@headlessui/react';
import { ReactComponent as ListsIcon } from './Utils/ui-components/Icons/Lists.svg';
import { ReactComponent as UrlIcon } from './Utils/ui-components/Icons/Url.svg';
// import { ReactComponent as CalendarIcon } from './Utils/ui-components/Icons/Calendar.svg';
import { ReactComponent as PinIcon } from './Utils/ui-components/Icons/Pin.svg';
import { ReactComponent as TypeIcon } from './Utils/ui-components/Icons/Type.svg';
import { withValidation } from '../hoc/withValidation';


const BottomMenuBar = (props) => {
  const { showNotification } = useContext(NotificationContext);
  const { validateForm, valid, errorDisplay, errors, ...rest } = props;
  const token = localStorage.getItem('jwtToken');
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [showAvailabilityCheck, setShowAvailabilityCheck] = useState(false);
  const [availabilityColor, setAvailabilityColor] = useState('');
  const [mainEnabled, setMainEnabled] = useState(false);
  const { refreshLists } = useContext(ListsContext);
  const [isSaving, setIsSaving] = useState(false);
  const submitButtonStyle = {};
  const marginTop = 20;
  
  if(errors.length) {
    submitButtonStyle['opacity'] = 0.4;
  } else {
    submitButtonStyle['opacity'] = 1;
  }

  const validationRules = {
    
    name: [
      {
        rule: v => v.length > 3,
        message: 'Numele listei trebuie sa aiba minim 4 caractere.'
      }
    ],
    eventType: {
      rule: v => v.length > 1,
        message: 'Selecteaza tipul listei.'
    }
  }

  useEscapeKey(() => {
    setIsOpen(false);
  });

  const openSlider = () => {
    if (token && token !== null) {
      setIsOpen(true);
    } else {
      navigate('/login');
    }
  };

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  const [formData, setFormData] = useState({
    name: '',
    uniqueURL: '',
    eventType: '',
    eventLocation: '',
    eventDatetime: '',
    eventPublic: true,
  });

  const {
    name,
    eventDatetime,
    eventLocation,
    eventPublic,
    eventType,
    uniqueURL,
  } = formData;

  const handleChange = (e) => {
    console.log('HERE', e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === 'name') {
      setFormData({ ...formData, name: e.target.value, uniqueURL: e.target.value.replace(/ /g, '-')});
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      eventDatetime: '',
      eventLocation: '',
      eventPublic: true,
      eventType: '',
      uniqueURL: '',
    });
    setShowAvailabilityCheck(false);
    setAvailabilityColor('');
    setMainEnabled(false);
  };

  useEffect(() => {
    if(props.create) {
      openSlider();
    }
  }, []);

  useEffect(() => {
    if (formData.uniqueURL.length > 2) {
      api
        .post(
          '/api/lists/check-url',
          {
            url: formData.uniqueURL,
          },
          { headers: { Authorization: `${token}` } }
        )
        .then((res) => {
          if (res.status === 200) {

            setShowAvailabilityCheck(true);
            if (res.data.msg) {
              setAvailabilityColor('green');
            } else {
              setAvailabilityColor('red');
            }
          } else {
            setShowAvailabilityCheck(false);
          }
        })
        .catch((err) => {
          setShowAvailabilityCheck(false);
        });
    } else if (formData.uniqueURL.length <= 2) {
      setShowAvailabilityCheck(false);
    }
  }, [formData.uniqueURL]);

  useEffect(() => {
    validateForm(formData, validationRules);
  }, [formData]);

  const location = useLocation();
  const [prevPath, setPrevPath] = useState('');
  useEffect(() => {
    setPrevPath((prevState) => {
      if(/*prevState === '/lists' &&*/ location.pathname === '/lists/create'){
        openSlider();
      }
      if(prevState === '/lists/create' && location.pathname === '/lists'){
        setIsOpen(false);
      }
      return location.pathname;
    });
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm(formData, validationRules);
    if (availabilityColor !== 'red' && !isSaving) {
      setIsSaving(true);
      api
        .post(
          '/api/lists/add',
          {
            name,
            eventDatetime,
            eventLocation,
            eventPublic,
            eventType,
            uniqueURL,
            main: mainEnabled,
          },
          { headers: { Authorization: `${token}` } }
        )
        .then((res) => {
          setIsSaving(false);
          if (res.status === 201) {
            showNotification('List creation successful', 'success');
            refreshLists();
            setIsOpen(false);
            resetForm();
          } else {
            showNotification('Error creating list', 'error');
          }
        })
        .catch((err) => {
          setIsSaving(false);
          showNotification('Error creating list', 'error');
        });
    }
  };
  return (
    <>
      <div className="w-[390px] sm:w-full md:w-[600px] lg:w-[800px] h-[61px] bg-black fixed bottom-0 z-50">
        {props.showAddListIcon && (
          <>
            <div className="cursor-pointer w-[94px] mt-[-47px] mx-auto">
              <AddListIcon
                data-testid="add-icon"
                onClick={() => {
                  openSlider();
                }}
              />
            </div>
            <div id="addListSlider" data-testid="addListSlider" className={isOpen ? 'open' : 'closed'}>
              <div className="absolute cursor-pointer right-[5px] top-[5px]">
                <CloseSlider
                  data-testid="close-add-list"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />
              </div>

              <h2 className="underline decoration-[#02CDB2] decoration-3 underline-offset-8 tracking-wider text-center text-2xl font-sans font-bold tracking-tight text-white mt-[30px]">
                Creaza o lista
              </h2>

              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <div>
                  <Switch
                    checked={mainEnabled}
                    onChange={setMainEnabled}
                    className={`${
                      mainEnabled ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full mr-[15px]`}
                  >
                    <span className="sr-only">Enable notifications</span>
                    <span
                      className={`${
                        mainEnabled ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                  Lista principala a profilului tau
                </div>

                <TextInput
                  type="text"
                  name="name"
                  placeholder={`Ziua mea ${new Date().getFullYear()}`}
                  required={true}
                  onChange={handleChange}
                  value={name}
                  label="Nume lista"
                  dataTestId="add-list-name"
                  errors={errors}
                  mt={marginTop}
                >
                  <ListsIcon />
                </TextInput>

                <SelectInput
                  options={eventTypes}
                  label="Tip eveniment"
                  id="eventType"
                  name="eventType"
                  value={eventType}
                  onChange={handleChange}
                  required
                  dataTestId="add-list-type"
                  errors={errors}
                  mt={marginTop}
                >
                  <TypeIcon />
                </SelectInput>

                <TextInput
                  type="text"
                  name="uniqueURL"
                  placeholder="ziua-mea"
                  onChange={handleChange}
                  value={uniqueURL}
                  label="Adresa personalizata"
                  extra={
                    <CheckAvailability
                      show={showAvailabilityCheck}
                      color={availabilityColor}
                      className="absolute right-[5px] top-0 mt-2 mr-2"
                    />
                  }
                  dataTestId="add-list-url"
                  errors={errors}
                  mt={marginTop}
                >
                  <UrlIcon />
                </TextInput>

                <TextInput
                  type="datetime-local"
                  name="eventDatetime"
                  placeholder="06-03-1980"
                  onChange={handleChange}
                  value={eventDatetime}
                  label="Ziua si ora"
                  dataTestId="add-list-date"
                  customClass='datetime'
                  errors={errors}
                  mt={marginTop}
                >
                  {/* <CalendarIcon /> */}
                </TextInput>

                <TextInput
                  type="text"
                  name="eventLocation"
                  placeholder="Strada Sperantei, nr. 1, Iasi"
                  onChange={handleChange}
                  value={eventLocation}
                  label="Adresa eveniment"
                  dataTestId="add-list-address"
                  errors={errors}
                  mt={marginTop}
                >
                  <PinIcon />
                </TextInput>

                <div className="pl-[15px] mt-4 space-y-4">
                  <div className="flex items-center">
                    <input
                      id="push-email"
                      name="eventPublic"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      value="private"
                      defaultChecked
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="push-email"
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      <span className="text-white text-base">
                        Lista privata
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="push-nothing"
                      name="eventPublic"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      value="public"
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="push-nothing"
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      <span className="text-white text-base">
                        Lista publica
                      </span>
                    </label>
                  </div>
                </div>
                <FormButton
                  mb="-10"
                  mt="50"
                  label="Creaza lista"
                  theme="green"
                  isLoading={isSaving}
                  style={submitButtonStyle}
                />
              </form>
            </div>
          </>
        )}
        <div>sddsf</div>
      </div>
    </>
  );
};

export default withValidation(BottomMenuBar);

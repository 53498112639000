//@TODO - formatarea datei sau latimea divului in care e data evenimentului - in list details , data trebuie sa fie pe un singur rand

import { searchUuidInLocalArray, formatName, removeObjectFromLocalArray } from '../../Utils';
import { ReservedLabel } from '../Utils/ReservedLabel';
import { ReserveButton } from '../Utils/ReserveButton';
import { BuyButton } from '../Utils/BuyButton';

export const PublicItemCard = (props) => {
    const reserveItem = (item) => {
        //if(!item.active)
        props.openReserveModal(item);
    };
    const buyItem = (item) => {
        //if(!item.active)
        props.openBuyModal(item);
    };
    const isItemInLocalList = searchUuidInLocalArray('listItem', props.item.uuid);
    const isItemInLocalBuyList = searchUuidInLocalArray('buyListItem', props.item.uuid);
    if(isItemInLocalList && props.item.active) {
        removeObjectFromLocalArray('listItem', props.item.uuid);
    }
    if(isItemInLocalBuyList && props.item.available) {
        removeObjectFromLocalArray('buyListItem', props.item.uuid);
    }
    const reserved = (props.item && !props.item.active && !isItemInLocalList) ? 'reserved' : '';
    const bought = (props.item && !props.item.available && !isItemInLocalBuyList) ? 'reserved' : '';

    const openItemLink = url => {
        console.log(url);
    }

    if(!props.plain && !props.small) {
        return (
            <div
                key={`list-${props.item.id}`}
                className="listItem cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden  lg:aspect-none lg:h-33"
            >
                <div
                style={{ }}
                className="border border-[#b482f2] border-opacity-80 relative min-h-[144px] max-h-[244px] mt-4 flex px-[0px] py-[0px] rounded-[15px] text-[#5800C1]"
                >
                    {props.item.image && (
                        <img className={`${reserved} ${bought} object-cover w-full rounded-[15px]`} src={`${props.item.image}`} alt={props.item.name} />
                    )}
                    <div className={`${reserved} ${bought} absolute bottom-[15px] right-[160px] z-30`}>
                        {(!props.item.active && isItemInLocalList) && <BuyButton myBuyList={isItemInLocalBuyList} bought={!props.item.available} className='itemButton' onClick={
                            (e) => {
                                buyItem(props.item);
                                e.stopPropagation();
                            }
                        }/>}
                    </div>
                    <div className={`${reserved} ${bought} absolute bottom-[15px] right-[5px] z-30`}>
                        <ReserveButton myReservation={isItemInLocalList} reserved={!props.item.active} className='itemButton' onClick={
                            (e) => {
                                reserveItem(props.item);
                                e.stopPropagation();
                            }
                        }/>
                    </div>
                    <div className={`absolute ${reserved} ${bought} bottom-0 h-[60px] w-full rounded-bl-[15px] rounded-br-[15px] z-20`}>
                        <div className="text-white font-sm p-[10px] w-[60%]" style={{textShadow: '#000 1px 0 4px'}}>
                            {formatName(props.item.name, 44)}
                        </div>
                    </div>
                    {(props.item && !props.item.active && !!props.item.available) && (<ReservedLabel />)}
                    {(props.item && !props.item.active && !props.item.available) && (<ReservedLabel bought={true} />)}
                    <div className={`${reserved} ${bought} font-sm text-white p-[5px] absolute bottom-0 h-[60px] bg-black w-full rounded-bl-[15px] rounded-br-[15px] opacity-30 z-10`}></div>
                    <div className={`${reserved} ${bought} absolute bottom-0 h-[60px] w-full rounded-bl-[15px] rounded-br-[15px] backdrop-blur-lg z-0`}></div>
                    <a className="absolute block w-full h-full right-0 top-0 z-10" data-testid="public-card-link" href={props.item.url} target="_blank"></a>

                </div>
            </div>
        );
    }
    if(!props.plain && props.small) {
        return (
            
            <div
                key={`list-${props.item.id}`}
                className="cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden  lg:aspect-none lg:h-33"
                onClick={() => {
                    //openItemLink(props.item.url);
                }}
                
            >
                <div
                style={{ }}
                className="border border-[#b482f2] border-opacity-50 relative h-[129px] mt-4 flex px-[0px] py-[0px] rounded-[15px] text-[#5800C1]"
                >
                    {(props.item && !props.item.active && !!props.item.available) && (
                        <ReservedLabel size="small" />
                    )}
                    {(props.item && !props.item.active && !props.item.available) && (
                        <ReservedLabel bought={true} size="small" />
                    )}
                    {props.item.image && (
                        <img className={`${reserved} ${bought} object-cover w-full rounded-[15px]`} src={`${props.item.image}`} alt={props.item.name} />
                    )}
                    <div className={`${reserved} ${bought} absolute bottom-[5px] left-[5px] z-30`}>
                    
                    {!props.item.active && isItemInLocalList && (

                        <BuyButton myBuyList={isItemInLocalBuyList} bought={!props.item.available} className='itemButton' onClick={
                            (e) => {
                                buyItem(props.item);
                                e.stopPropagation();
                            }
                        }/>

                    )}
                    </div>
                    <div className={`${reserved} ${bought} absolute bottom-[5px] right-[5px] z-30`}>
                        <ReserveButton myReservation={isItemInLocalList} reserved={!props.item.active} size="small" className='itemButton' onClick={
                            (e) => {
                                reserveItem(props.item);
                                e.stopPropagation();
                            }
                        }/>
                    </div>
                    
                    <a className="absolute block w-full h-full right-0 top-0 z-10" data-testid="public-card-link" href={props.item.url} target="_blank"></a>
                    
                </div>
                <div className="">
                    <div className="text-white text-xs pt-[3px] pl-[10px] pb-[10px]" style={{textShadow: '#000 1px 0 4px'}}>
                        {formatName(props.item.name, 44)}
                    </div>
                </div>
            </div>
        );
    }
    if(props.plain) {
        return (
            <div onClick={() => {}} className={`${reserved} ${bought} socialButtons relative min-h-[54px] before:rounded-[15px] items-center justify-center before:absolute before:block before:h-full before:w-full before:top-0 before:left-0 before:content-[''] before:bg-white/70 before:mix-blend-overlay pb-[10px] pl-[15px] pr-[15px] pt-[15px]`}>
                <div className="relative grid grid-cols-3">
                    <div className="col-span-2 text-white font-md">{formatName(props.item.name, 25)}</div>
                    <div className="absolute bottom-[-4px] right-[40px] z-30">
                    {!props.item.active && <BuyButton myBuyList={isItemInLocalBuyList} bought={!props.item.available} className='itemButton' onClick={
                            (e) => {
                                buyItem(props.item);
                                e.stopPropagation();
                            }
                        }/>}
                    </div>
                    <div className="absolute bottom-[-4px] right-[0px] z-30">
                        <ReserveButton myReservation={isItemInLocalList} reserved={!props.item.active} size="small" className='itemButton' onClick={
                            (e) => {
                                reserveItem(props.item);
                                e.stopPropagation();
                            }
                        }/>
                    </div>
                </div>
                {(props.item && !props.item.active && props.item.available) && (<ReservedLabel size="xsmall"  />)}
                {(props.item && !props.item.active && !props.item.available) && (<ReservedLabel bought={true} size="xsmall"  />)}
            </div>
        );
    }
}
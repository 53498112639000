  import { useEffect } from 'react';

  export function useEscapeKey(callback) {
    useEffect(() => {
      const handleEsc = (event) => {
        if (event.key === 'Escape') callback();
      };
      window.addEventListener('keydown', handleEsc);
  
      // Clean up function
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }, [callback]); // Ensures effect updates if callback changes
  }
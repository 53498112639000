import {
  purpleGradient,
  reverseGreenGradient,
  greenGradient,
  reversePurpleGradient,
} from '../../../Utils/constants';
import { ReactComponent as Spinner } from './Icons/Spinner.svg';

export const FormButton = ({ children, ...props }) => {
  const type = props.type ? props.type : 'submit';
  const theme = props.theme ? props.theme : 'green';
  const onClick = props.onClick ? props.onClick : null;
  const isLoading = props.isLoading || false;
  return (
    <div
      className="relative w-full rounded-[25px] bg-black h-[42px] p-[1px] mt-[5px]"
      style={{
        background:
          theme === 'green' ? reverseGreenGradient : reversePurpleGradient,
        marginTop: `${props.mt ? props.mt : 0}px`,
        marginBottom: `${props.mb ? props.mb : 0}px`,
        width: `${props.width ? props.width : '100%'}`,
        opacity: props.style && props.style.opacity,
      }}
    >
      {children && (
        <div className="absolute left-[15px] top-[10px]">{children}</div>
      )}

      <button
        type={type}
        style={{
          opacity: props.style && props.style.opacity,
          background: theme === 'green' ? greenGradient : purpleGradient,
          paddingLeft:
            props.style && props.style.paddingLeft
              ? props.style.paddingLeft
              : 0,
          paddingRight:
            props.style && props.style.paddingRight
              ? props.style.paddingRight
              : 0,
        }}
        name={props.name}
        className="block relative text-center w-full rounded-[24px] h-[40px] py-1.5 text-white  focus:z-10 focus:ring-2  sm:text-sm sm:leading-6"
        value={props.value}
        onChange={props.onChange}
        onClick={onClick}
        data-testid={props.dataTestId}
      >
        {isLoading && <span className="absolute left-[50%] ml-[-75px] spinner"><Spinner /></span>}
        {props.label}
      </button>
    </div>
  );
};

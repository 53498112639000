import { useState, useEffect, useContext } from 'react';
import { NotificationContext } from '../Utils/contexts/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { Spacer } from "../Utils/ui-components/Spacer";
import { TextInput } from '../Utils/ui-components/TextInput';
import { InlineEditorGeneral } from '../Utils/InlineEditorGeneral';
import { FormButton } from '../Utils/ui-components/FormButton';
import Confirmation from '../Utils/Dialog';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { ReactComponent as CheckIcon } from "../Utils/ui-components/Icons/ResetCheck.svg";
import { ReactComponent as KeyIcon } from "../Utils/ui-components/Icons/Key.svg";
import { ReactComponent as CameraIcon } from "../Utils/ui-components/Icons/Camera.svg";
import { ReactComponent as AvatarIcon } from "../Utils/ui-components/Icons/UserBig.svg";
import { ReactComponent as DeleteIcon } from "../Utils/ui-components/Icons/XCircle.svg";
import Camera, {FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ReactDOM from 'react-dom';

import axios from 'axios';
import { withValidation } from '../../hoc/withValidation';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

const UserProfile = (props) => {
  const { validateForm, valid, errorDisplay, errors, ...rest } = props;
  const { showNotification } = useContext(NotificationContext);
  const submitButtonStyle = {};
  const token = localStorage.getItem('jwtToken');
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [showCamera, setShowCamera] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const marginTop = 20;
  if(errors.length) {
    submitButtonStyle['opacity'] = 0.4;
  } else {
    submitButtonStyle['opacity'] = 1;
  }

  const portalRoot = document.getElementById('camera-portal');

  const [formData, setFormData] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: ""
  });

  const { oldPassword, password, confirmPassword } = formData;

  const validationRules = {
    
    password: [
      {
        rule: () => password === confirmPassword,
        message: 'Cele doua parole nu corespund'
      },
      {
        rule: () => /[A-Z]/.test(password),
        message: 'Parola trebuie sa contina o litera mare.'
      },
      {
        rule: () => /\d/.test(password),
        message: 'Parola trebuie sa contina o cifra.'
      },
      {
        rule: v => v.length > 7,
        message: 'Parola trebuie sa aiba minim 8 caractere.'
      }
    ]
  }

  const handleTakePhoto = async (dataUri) => {
    try {
      const response = await api.post(`/api/users/profile/update/image/${user.id}`, {
        image: dataUri
      },{
        headers: { Authorization: `${token}` },
      });

      const jsonResponse = response.data.image;
      setShowCamera(false);
      let updatedUser = {...user, avatar: jsonResponse};
      localStorage.setItem("user", JSON.stringify(updatedUser));
      setUser(updatedUser);

    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  }

  const [selectedFile, setSelectedFile] = useState(null);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = async () => {
    const formData = new FormData();

    // Update the formData object
    if(selectedFile) {

      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = async () => {
        try {
          const base64 = reader.result;
          const response = await api.post(`/api/users/profile/update/image/${user.id}`, {
            image: base64
          },{
            headers: { Authorization: `${token}` },
          });
          
          const jsonResponse = response.data.image;
          let updatedUser = {...user, avatar: jsonResponse};
          localStorage.setItem("user", JSON.stringify(updatedUser));
          setUser(updatedUser);
        } catch (error) {
          console.error('Error uploading file: ', error);
        }
      };
      reader.onerror = () => {
        console.error('Error reading file');
      };
    }
  };

  const takeProfilePhoto = () => {
    setShowCamera(true);
  }

  const handleSubmit = async (e) => {
      e.preventDefault();

      validateForm(formData, validationRules);

      if(!valid) {
          return;
      }

      setIsLoading(true);
      api
      .post("/api/auth/change-password/profile", { oldPassword, password, confirmPassword }, {
          headers: { Authorization: `${token}` },
      })
      .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
          showNotification("Parola a fost schimbata.", "success");
          setTimeout(() => {navigate("/login")}, 3000)
          
          } else {
          showNotification("Ne pare rau, nu putem schimba parola pentru acest user.", "error");
          }
      })
      .catch((err) => {
          setIsLoading(false);
          showNotification("Ne pare rau, a intervenit o eroare si nu putem schimba parola pentru acest user.", "error");
      });
      
    };

    const removeAvatar = async () => {
      try {
        const response = await api.put(`/api/users/profile/update/${user.id}`, {
          avatar: null
        },{
          headers: { Authorization: `${token}` },
        });
    
        const jsonResponse = response.data.status;
        let updatedUser = {...user, avatar: null};
        localStorage.setItem("user", JSON.stringify(updatedUser));
        setUser(updatedUser);
    
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    }

    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const updateNameSuccessCallback = (value) => {
      let updatedUser = {...user, name: value};
      localStorage.setItem("user", JSON.stringify(updatedUser));
      setUser(updatedUser);
    }

    const updateProfileSuccessCallback = (value) => {
      let updatedUser = {...user, profileName: value};
      localStorage.setItem("user", JSON.stringify(updatedUser));
      setUser(updatedUser);
    }

    const openDeleteModal = (id) => {
      setOpenModal(true);
    };

    const closeCamera = () => {
      setShowCamera(false);
    }
    
    useEffect(() => {
      validateForm(formData, validationRules);
    }, [formData]);
    
    useEffect(() => {
      if(token === null) {
        navigate('/login');
      }
    }, [token, navigate]); // dependencies of the useEffect hook

    const u1 = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
      console.log('HEEEEEREEE');
      setUser(u1);
    }, []);
  

  console.log('RENDER', user);
  return (
    <>
      <Confirmation
        executeAction={removeAvatar}
        openModal={openModal}
        setOpenModal={setOpenModal}
        title="Sterge imaginea de profil"
        warning = "Esti sigur ca vrei sa stergi imaginea?"
        labels={{
          confirm: 'Sterge',
          cancel: 'Anuleaza'
        }}
      />
      {showCamera && 
          ReactDOM.createPortal(
            <>
              <div className="absolute right-[20px] top-[20px]" style={{zIndex: 60}}><XCircleIcon onClick={closeCamera} className='w-[64px] fill-white' /></div>
              <Camera
                onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                isFullscreen={true}
                idealFacingMode={FACING_MODES.USER}
              />
            </>,
            portalRoot
          )
      }
      <div className="min-h-[600px]">
          <h2 className="underline decoration-[#02CDB2] decoration-3 underline-offset-8 tracking-wider text-center text-2xl font-sans font-bold tracking-tight text-white">
              Contul tau
          </h2>

          <div className="">
              <div className="text-white mx-auto w-[90%] text-center my-[40px] underline decoration-[#D65289] decoration-3 underline-offset-8 text-l">
                  Editeaza informatiile tale
              </div>

              <div className="w-[90%] text-white mx-auto mt-6 grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
                <div className="cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden  lg:aspect-none lg:h-33">
                  <div>
                  Nume: <InlineEditorGeneral
                          fieldName={`name`}
                          value={user.name}
                          id={user.id}
                          saveUrl='/api/users/profile/update/'
                          updateSuccessCallback={updateNameSuccessCallback}
                        />
                  </div>
                  <div className="mt-[20px]">
                  Nume Profil: <InlineEditorGeneral
                          fieldName={`profileName`}
                          value={user.profileName}
                          saveUrl='/api/users/profile/update/'
                          checkUnique={true}
                          checkUrl="/api/users/check-profilename/" 
                          updateSuccessCallback={updateProfileSuccessCallback}
                          id={user.id}
                        />
                  </div>
                </div>
                <Spacer mb="30" mt="30" className="lg:hidden sm:block" />
                <div className="cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden  lg:aspect-none lg:h-33">
                  <div className='justify-center flex'>
                    <CameraIcon onClick={takeProfilePhoto} /> 
                    <a className="ml-[5px] text-[#02CDB3] underline" onClick={takeProfilePhoto}>Foloseste camera</a>
                  </div>
                  <div className="flex justify-center mt-[20px]" >
                    {user.avatar && (
                      <div className='relative'>
                        <img onClick={takeProfilePhoto} className='rounded-[50%] object-cover w-[100px] h-[100px]' src={`${user.avatar}`} />
                        <DeleteIcon onClick={() => {openDeleteModal(user.id)}} className='absolute pointer w-[32px] right-[-10px] top-[-10px]' />
                      </div>
                    )}
                    {!user.avatar && (
                      <AvatarIcon className='w-[100px] h-[100px]' />
                    )}
                    
                  </div>
                  <div className='mt-[20px] text-center '>
                    <input type="file" className='w-[195px]' onChange={onFileChange} />
                    {selectedFile && (
                      <a className="text-[#02CDB3] underline" onClick={onFileUpload}>Incarca imaginea</a>
                    )}
                    
                  </div>
                </div>
              </div>
          </div>
          <Spacer mb="30" mt="30" />
          <div className="">
              <div className="text-white mx-auto w-[90%] text-center mt-[40px] underline decoration-[#D65289] decoration-3 underline-offset-8 text-l">
                  Schimba-ti parola
              </div>

              <div className="w-[90%] mx-auto">
              <form className="mt-8 space-y-6 w-full" onSubmit={handleSubmit}>
                  <TextInput
                  type="password"
                  name="oldPassword"
                  required={true}
                  onChange={handleChange}
                  value={oldPassword}
                  label="Parola curenta"
                  errors={errors}
                  mt={marginTop}
                  >
                  <CheckIcon />
                  </TextInput>
                  <Spacer mb="30" mt="30" />
                  <TextInput
                  type="password"
                  name="password"
                  required={true}
                  onChange={handleChange}
                  value={password}
                  label="Parola noua"
                  errors={errors}
                  mt={marginTop}
                  >
                  <KeyIcon />
                  </TextInput>
                  <TextInput
                  type="password"
                  name="confirmPassword"
                  required={true}
                  onChange={handleChange}
                  value={confirmPassword}
                  label="Confirma parola"
                  errors={errors}
                  mt={marginTop}
                  >
                  <KeyIcon />
                  </TextInput>
                  <FormButton mb="-10" mt="50" label="SCHIMBA PAROLA" theme="green" dataTestId="login-button" style={submitButtonStyle} isLoading={isLoading}/>
                  
              </form>
              <Spacer mb="30" mt="30" />
              </div>
          </div>
      </div>
    </>
  );

}   

export default withValidation(UserProfile);


export const Spacer = (props) => {
  const mixBlendMode = props.noBlend ? 'normal' : 'overlay';
  return (
    <div
      className={`w-[90%] mx-auto border h-[1px] bg-white ${props.className}`}
      style={{
        marginTop: `${props.mt ? props.mt : 0}px`,
        marginBottom: `${props.mb ? props.mb : 0}px`,
        mixBlendMode: mixBlendMode,
      }}
    ></div>
  );
};

export const CardLoader = (props) => {
    return (
        <div className="w-full mt-6 grid grid-cols-2 gap-y-3 gap-x-3 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
            <div className="cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden lg:aspect-none lg:h-33 opacity-30 animate-pulse">
            <div className="relative bg-white  min-h-[144px] mt-4 flex px-[5px] py-[10px] rounded-[15px] text-[#5800C1]"></div>
            </div>  
            <div className="cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden lg:aspect-none lg:h-33 opacity-30 animate-pulse" style={{'animationDelay':'0.25s'}}>
            <div className="relative bg-white  min-h-[144px] mt-4 flex px-[5px] py-[10px] rounded-[15px] text-[#5800C1]"></div>
            </div>
            <div className="cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden lg:aspect-none lg:h-33 opacity-30 animate-pulse" style={{'animationDelay':'0.5s'}}>
            <div className="relative bg-white  min-h-[144px] mt-4 flex px-[5px] py-[10px] rounded-[15px] text-[#5800C1]"></div>
            </div>
            <div className="cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden lg:aspect-none lg:h-33 opacity-30 animate-pulse" style={{'animationDelay':'1s'}}>
            <div className="relative bg-white  min-h-[144px] mt-4 flex px-[5px] py-[10px] rounded-[15px] text-[#5800C1]"></div>
            </div>
        </div>
    );
};
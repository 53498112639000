import { Animation } from './Animation';

export function Home() {
  console.log('FROM ENV', process.env.REACT_APP_TEST);
  return (
    <div>
      <Animation />
    </div>
  );
}

import { ReactComponent as BuyButtonSVG } from '../Utils/ui-components/Icons/Buy.svg';
import { ReactComponent as CancelBuyButtonSVG } from '../Utils/ui-components/Icons/BuyCancel.svg';

export const BuyButton = props => {

    if(!props.bought) {
        return (
            <BuyButtonSVG  data-testid="buy-button" onClick={props.onClick} />
        );
    } else {
        if(props.myBuyList) {
            return (
                <CancelBuyButtonSVG data-testid="cancel-buy-button" onClick={props.onClick} />
            );
        } else {
            return (<></>);
        }
    }
}
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { NOTIFICATION_FADE_INTERVAL } from '../../Utils/constants';
import {SocialLogins} from "../Utils/SocialLogins";
import { Spacer } from '../Utils/ui-components/Spacer';
import { TextInput } from '../Utils/ui-components/TextInput';
import { FormButton } from '../Utils/ui-components/FormButton';
import CheckAvailability from '../Utils/CheckAvailability';
import { ReactComponent as EnvelopeIcon } from '../Utils/ui-components/Icons/Envelope.svg';
import { ReactComponent as UserIcon } from '../Utils/ui-components/Icons/User.svg';
import { ReactComponent as KeyIcon } from '../Utils/ui-components/Icons/Key.svg';
import {ReactComponent as ProfileIcon} from '../Utils/ui-components/Icons/ShareRegister.svg';
import { withValidation } from '../../hoc/withValidation';
import { NotificationContext } from '../Utils/contexts/NotificationContext';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const Register = (props) => {
  const [formData, setFormData] = useState({
    name: '',
    profileName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { validateForm, valid, errorDisplay, errors, ...rest } = props;
  
  const { showNotification } = useContext(NotificationContext);
  const [showAvailabilityCheck, setShowAvailabilityCheck] = useState(false);
  const [availabilityColor, setAvailabilityColor] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { name, email, profileName, password, confirmPassword } = formData;
  const navigate = useNavigate();
  const marginTop = 20;
  const submitButtonStyle = {};
  if(errors.length) {
    submitButtonStyle['opacity'] = 0.4;
  } else {
    submitButtonStyle['opacity'] = 1;
  }
  
  const validationRules = {
    email: [
      {
        rule: v => v.length > 2,
        message: 'Emailul trebuie sa aiba minim 3 caractere.'
      },
      {
        rule: () => /^\S+@\S+\.\S+$/.test(email),
        message: 'Adresa de email e incorecta.'
      }
    ],
    password: [
      {
        rule: () => password === confirmPassword,
        message: 'Cele doua parole nu corespund'
      },
      {
        rule: () => /[A-Z]/.test(password),
        message: 'Parola trebuie sa contina o litera mare.'
      },
      {
        rule: () => /\d/.test(password),
        message: 'Parola trebuie sa contina o cifra.'
      },
      {
        rule: v => v.length > 7,
        message: 'Parola trebuie sa aiba minim 8 caractere.'
      }
    ],
    name: {
      rule: v => v.length > 2,
      message: 'Numele trebuie sa aiba minim 3 caractere.'
    },
    profileName: {
      rule: v => v.length > 2,
      message: 'Numele de profil trebuie sa aiba minim 3 caractere.'
    }
  };

  const handleChange = (e) => {
    setFormData(prevFormData => ({
      ...prevFormData, 
      [e.target.name]: e.target.value
    }));
    
    if (e.target.name === 'profileName' && e.target.value.length > 2) {
      
      api
        .post(
          '/api/users/check-profilename',
          {
            profileName: e.target.value,
          }
        )
        .then((res) => {
          
          if (res.status === 200) {
            setShowAvailabilityCheck(true);
            if (res.data.msg) {
              setAvailabilityColor('green');
            } else {
              setAvailabilityColor('red');

            }
          } else {
            setShowAvailabilityCheck(false);
          }
        })
        .catch((err) => {
          setShowAvailabilityCheck(false);
        });
    } else if (e.target.name === 'profileName' && e.target.value.length <= 2) {
      setShowAvailabilityCheck(false);
    }
  };

  useEffect(() => {
    validateForm(formData, validationRules);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateForm(formData, validationRules);

    if(!valid) {
      return;
    }

    if (availabilityColor === 'red') {
      showNotification('Numele de profil este deja folosit de altcineva. Incearca altul te rog.', 'error');
      return;
    }
    setIsLoading(true);
    api
      .post('/api/users', { email, name, password, confirmPassword, profileName })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 201) {
          showNotification('Contul a fost creat cu succes!', 'success');
          setTimeout(() => {navigate('/login');}, NOTIFICATION_FADE_INTERVAL);
        } else {
          showNotification(res.data.message, 'error'); 
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification('Error registering user!', 'error');
      });
    
  };

  return (
    <>
      <h2 className="underline decoration-[#02CDB2] decoration-3 underline-offset-8 tracking-wider text-center text-2xl font-sans font-bold tracking-tight text-white">
        Fa-ti un cont
      </h2>
      <div className="">
        <SocialLogins />
        <Spacer mb="40" mt="50" />
        <div className="text-white mx-auto w-[90%] text-center">sau</div>
        <div className="w-[90%] mx-auto">

          {errorDisplay}

          <form className="mt-8 space-y-6 mb-[100px]" onSubmit={handleSubmit}>
            
            <TextInput
              type="email"
              name="email"
              placeholder="user@domain.com"
              //required={true}
              onChange={handleChange}
              value={email}
              label="Email"
              
              errors={errors}
            >
              <EnvelopeIcon />
            </TextInput>

            <TextInput
              type="text"
              name="name"
              placeholder="Ion Ionescu"
              //required={true}
              onChange={handleChange}
              value={name}
              label="Nume"
              mt={marginTop}
              
              errors={errors}
            >
              <UserIcon />
            </TextInput>

            <TextInput
              type="text"
              name="profileName"
              placeholder="ion.ionescu"
              //required={true}
              onChange={handleChange}
              value={profileName}
              label="Nume profil"
              mt={marginTop}
              
              errors={errors}
              extra={
                <CheckAvailability
                  show={showAvailabilityCheck}
                  color={availabilityColor}
                  className="absolute right-[5px] top-0 mt-2 mr-2"
                />
              }
            >
              <ProfileIcon />
            </TextInput>

            <TextInput
              type="password"
              name="password"
              placeholder="password"
              //required={true}
              onChange={handleChange}
              value={password}
              label="Parola"
              mt={marginTop}
              errors={errors}
            >
              <KeyIcon />
            </TextInput>

            <TextInput
              type="password"
              name="confirmPassword"
              placeholder="password"
              //required={true}
              onChange={handleChange}
              value={confirmPassword}
              label="Confirma parola"
              mt={marginTop}
              
              errors={errors}
            >
              <KeyIcon />
            </TextInput>

            <FormButton
              mb="-10"
              mt="50"
              label="INREGISTREAZA-TE"
              theme="green"
              isLoading={isLoading}
              style={submitButtonStyle}
            />
          </form>
          <br />
        </div>
      </div>
    </>
  );
};

export default withValidation(Register);

import { Menu } from '../Utils/Menu';
import BottomMenuBar from '../BottomMenuBar';
import { NotificationProvider } from '../Utils/contexts/NotificationContext';

export function Layout(props) {
  
  const { bgImg, children } = props;

  return (
    <div className="relative h-full w-[390px] sm:w-full md:w-[600px] lg:w-[800px]">
      <Menu />

      <div
        className="bg-no-repeat w-full h-[323px]"
        style={{
          backgroundImage: `url('/assets/images/${bgImg}')`,
          backgroundPosition: '50% -175px',
        }}
      ></div>

      <div className="mt-[45px] mx-auto h-[47px] w-[147px] content-center">
        <img
          src="/assets/images/logo.png"
          alt="Your Logo"
          className="h-[47px] w-[147px]"
        />
      </div>

      <div
        style={{
          background:
            'radial-gradient(91.89% 91.89% at 86.39% 8.11%, #5800C1 0%, #1A022B 100%)',
        }}
        className="pb-[100px] pt-[42px] h-full px-[12px] mx-auto mt-[42px] bg-gradient-to-br from-purple-600 to-purple-800 rounded-tl-[15px] rounded-tr-[15px]"
      >
        {children}
      </div>
      <NotificationProvider>
        <BottomMenuBar />
      </NotificationProvider>
    </div>
  );
}

import { Fragment, useRef, useState, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Camera, {FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ReactDOM from 'react-dom';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { ReactComponent as CameraIcon } from "./ui-components/Icons/Camera.svg";
import { NotificationContext } from '../Utils/contexts/NotificationContext';
import { ReactComponent as Spinner } from '../Utils/ui-components/Icons/Spinner.svg';
import axios from 'axios';

export default function Confirmation(props) {
  //const [open, setOpen] = useState(false);
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  const token = localStorage.getItem('jwtToken');

  const { showNotification } = useContext(NotificationContext);
  const cancelButtonRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const confirmAction = () => {
    props.executeAction();
    props.setOpenPhotoModal(false);
  };
  const portalRoot = document.getElementById('camera-portal');

  const parseCurrentImage = () => {
    console.log('IMAGE', props.item.image);
    if(props.item.image) {
      const m = props.item.image.match(/\/([^\/]+)(?=\.\w+$)/);
      console.log('IMAGE 2', m);
      if(m && m[1]) {
        return m[1];
      }
    } 
    return false;
  }

  const deleteOldImage = async file => {
    if(file) {
      api.post(`/api/files/delete/${file}`,
      null,
      { headers: { Authorization: `${token}` } })
      .then((res) => {
        if (res.status === 200) {
          props.updateItemsInfo(props.data.uuid, 'image', null);
        } else {
          //console.log('ERROR STATUS');
        }
      })
      .catch((err) => {
        //console.log('CAUGHT ERROR');
      });
    } 
    
  }

  const handleTakePhoto = async (dataUri) => {
    try {
      setIsUploading(true);
      const response = await api.post(`/api/items/update/image/${props.item.uuid}`, {
        image: dataUri
      },{
        headers: { Authorization: `${token}` },
      });

      const image = response.data.image;
      props.updateItemsList(props.item.uuid, 'image', image);
      setShowCamera(false);
      props.setOpenPhotoModal(false);
      showNotification('Imaginea a fost adaugata.', 'success');
      setIsUploading(false);
      //delete old image
      const currentImage = parseCurrentImage();
      if(currentImage) {
        await deleteOldImage(currentImage);
      }

    } catch (error) {
      console.log('ADD ERROR', error);
      showNotification('Imaginea nu a putut fi adaugata 1.', 'error');
      setIsUploading(false);
    }
  }

  const [selectedFile, setSelectedFile] = useState(null);

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = async () => {
    const formData = new FormData();

    // Update the formData object
    if(selectedFile) {
      const currentImage = parseCurrentImage();
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = async () => {
        try {
          setIsUploading(true);
          const base64 = reader.result;
          const response = await api.post(`/api/items/update/image/${props.item.uuid}`, {
            image: base64
          },{
            headers: { Authorization: `${token}` },
          });
          
          const image = response.data.image;
          props.updateItemsList(props.item.uuid, 'image', image);
          setIsUploading(false);
          props.setOpenPhotoModal(false);
          showNotification('Imaginea a fost adaugata.', 'success');

          //delete old image
          if(currentImage) {
            await deleteOldImage(currentImage);
          }

        } catch (error) {
          showNotification('Imaginea nu a putut fi adaugata.', 'error');
          setIsUploading(false);
        }
      };
      reader.onerror = () => {
        showNotification('Imaginea nu a putut fi adaugata.', 'error');
        setIsUploading(false);
      };
    }
  };

  const takeProfilePhoto = () => {
    setShowCamera(true);
  }

  const closeCamera = () => {
    setShowCamera(false);
  }

  return (
    <>
      {showCamera && 
          ReactDOM.createPortal(
            <>
              <div className="absolute right-[20px] top-[20px]" style={{zIndex: 60}}><XCircleIcon onClick={closeCamera} className='w-[64px] fill-white' /></div>
              <Camera
                onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                isFullscreen={true}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
              />
            </>,
            portalRoot
          )
      }
    
      <Transition.Root show={props.openModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={props.setOpenPhotoModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-100 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {props.title}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {props.warning}
                          </p>

                          <div className="cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden  lg:aspect-none lg:h-33">
                            <div className='justify-center flex'>
                              <CameraIcon onClick={takeProfilePhoto} /> 
                              <a className="ml-[5px] text-[#02CDB3] underline" onClick={takeProfilePhoto}>Foloseste camera</a>
                            </div>
                            <div className="flex justify-center mt-[20px]" >
                              
                            {isUploading && (
                              <div className="spinner"><Spinner /></div>
                            )}
                            </div>
                            <div className='mt-[20px] text-center '>
                              <input type="file" className='w-[195px]' onChange={onFileChange} />
                              {selectedFile && (
                                <a className="text-[#02CDB3] underline" onClick={onFileUpload}>Incarca imaginea</a>
                              )}
                              
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      ref={cancelButtonRef}
                      onClick={() => {
                        props.setOpenPhotoModal(false);
                      }}
                    >
                      {props.labels.cancel}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

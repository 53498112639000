import { useEffect, useRef } from "react";
import { gsap } from "gsap";

export const Animation = () => {
  const rectangles = [];
  rectangles[0] = useRef(null);
  rectangles[1] = useRef(null);
  rectangles[2] = useRef(null);
  rectangles[3] = useRef(null);
  rectangles[4] = useRef(null);

  useEffect(() => {
    rectangles.forEach((ref) => {
      // Animate the rectangles fading in and bobbing up and down
      gsap.fromTo(
        ref.current,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          delay: Math.random() * 2, // Random delay for the fade in
          y: '+=' + (Math.random() * (35 - 15) + 15),
          x: '+=' + (Math.random() * (35 - 15) + 15),
          repeat: -1,
          yoyo: true,
          duration: 2 + Math.random() * 2, // Random duration for the bobbing animation
          ease: 'power1.inOut',
        }
      );
    });
  }, []);

  return (
    <div className="flex flex-wrap justify-around items-center h-screen">
      {rectangles.map((ref, i) => (
        <div
          key={i}
          ref={ref}
          className={`w-64 h-40 bg-blue-500 rounded-lg opacity-0 m-4 transform translate-x-${Math.floor(Math.random() * 10) - 5} translate-y-${Math.floor(Math.random() * 10) - 5}`}
        />
      ))}
    </div>
  );
};

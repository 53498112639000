import { SocialLoginButton } from "./ui-components/SocialLoginButton";
import { googleLogin, discordLogin } from '../../Utils';
import { ReactComponent as GoogleIcon } from "./ui-components/Icons/GoogleButton.svg";
import { ReactComponent as DiscordIcon } from "./ui-components/Icons/DiscordButton.svg";

export const SocialLogins = () => {
    return (
        <div
          id="socialLogins"
          className="w-full grid grid-cols-2 gap-3 mt-[55px]"
        >
          <SocialLoginButton onClick={googleLogin} label="Login cu Google">
            <GoogleIcon />
          </SocialLoginButton>
          <SocialLoginButton onClick={discordLogin}  label="Login cu Discord">
            <DiscordIcon />
          </SocialLoginButton> 
        </div>
    );
}
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Spacer } from "../Utils/ui-components/Spacer";
import { TextInput } from "../Utils/ui-components/TextInput";
import { FormButton } from "../Utils/ui-components/FormButton";
import { NotificationContext } from '../Utils/contexts/NotificationContext';
import { ReactComponent as EnvelopeIcon } from "../Utils/ui-components/Icons/Envelope.svg";
import { withValidation } from '../../hoc/withValidation';
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const Recover = (props) => {

    const { showNotification } = useContext(NotificationContext);
    const { validateForm, valid, errorDisplay, errors, ...rest } = props;
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const submitButtonStyle = {};
    if(errors.length) {
      submitButtonStyle['opacity'] = 0.4;
    } else {
      submitButtonStyle['opacity'] = 1;
    }
    const [formData, setFormData] = useState({
      email: ""
    });

    const { email } = formData;

    const validationRules = {
      email: [
        {
          rule: v => v.length > 2,
          message: 'Emailul trebuie sa aiba minim 3 caractere.'
        },
        {
          rule: () => /^\S+@\S+\.\S+$/.test(email),
          message: 'Adresa de email e incorecta.'
        }
      ]
    }

    const handleSubmit = async (e) => {
      e.preventDefault();

      validateForm(formData, validationRules);

      if (email.trim === null) {
        showNotification("Adauga o adresa de email.", "error");
      } else {
        setIsLoading(true);
        api
          .post("/api/auth/recover", { email })
          .then((res) => {
            setIsLoading(false);
            if (res.status === 200) {
              navigate("/recover/reset-password");
            } else {
              showNotification("Ne pare rau, nu putem reseta parola pentru aceasta adresa de email.", "error");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            showNotification("Ne pare rau, a intervenit o eroare si nu putem reseta parola pentru aceasta adresa de email.", "error");
          });
      }
    };

    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
      validateForm(formData, validationRules);
    }, [formData]);

    return (
        <>
            <h2 className="underline decoration-[#02CDB2] decoration-3 underline-offset-8 tracking-wider text-center text-2xl font-sans font-bold tracking-tight text-white">
                Reseteaza parola
            </h2>
            <div className="">
                <div className="text-white mx-auto w-[90%] text-center mt-[40px]">
                    Daca ai uitat parola, introdu adresa de email cu care ti-ai creat contul. Vei primi un email cu un link si un cod de validare.
                </div>
                <div className="w-[90%] mx-auto">
                    <form className="mt-8 space-y-6 w-full" onSubmit={handleSubmit}>
                        <TextInput
                          type="email"
                          name="email"
                          placeholder="user@domain.com"
                          required={true}
                          onChange={handleChange}
                          value={email}
                          label="Email"
                          errors={errors}
                        >
                          <EnvelopeIcon />
                        </TextInput>

                        <FormButton
                          mb="-10"
                          mt="50"
                          label="RESETEAZA PAROLA"
                          theme="green"
                          dataTestId="recover-button" 
                          isLoading={isLoading}
                          style={submitButtonStyle}
                        />

                        <div className="w-full text-center ">
                        <Link to="/login" className="text-[#02CDB3] hover:underline">
                            Ti-ai amintit parola?
                        </Link>
                        </div>
                    </form>
                    <Spacer mb="30" mt="30" />
                </div>
            </div>
        </>
    );
}

export default withValidation(Recover);

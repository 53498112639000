export const SocialLoginButton = ({ children, ...props }) => {
  return (
    <button onClick={props.onClick} className="socialButtons relative before:rounded-[15px] items-center justify-center before:absolute before:block before:h-full before:w-full before:top-0 before:left-0 before:content-[''] before:bg-white/70 before:mix-blend-overlay p-[10px]">
      <div className="relative grid grid-cols-3">
        <div className="col-span-1">{children}</div>
        <div className="col-span-2 text-sm font-sans font-normal text-white flex items-center">
          {props.label}
        </div>
      </div>
    </button>
  );
};

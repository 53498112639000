import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Spacer } from "../Utils/ui-components/Spacer";
import { TextInput } from "../Utils/ui-components/TextInput";
import { FormButton } from "../Utils/ui-components/FormButton";
import { NotificationContext } from '../Utils/contexts/NotificationContext';
import { ReactComponent as CheckIcon } from "../Utils/ui-components/Icons/ResetCheck.svg";
import { ReactComponent as KeyIcon } from "../Utils/ui-components/Icons/Key.svg";
import { useParams } from 'react-router-dom';
import { withValidation } from '../../hoc/withValidation';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const ResetPassword = (props) => {

  const { validateForm, valid, errorDisplay, errors, ...rest } = props;
  const { showNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(false);
  const submitButtonStyle = {};
  const marginTop = 20;
  if(errors.length) {
    submitButtonStyle['opacity'] = 0.4;
  } else {
    submitButtonStyle['opacity'] = 1;
  }
  
  const navigate = useNavigate();
  const params = useParams();
  const [formData, setFormData] = useState({
      resetToken: params.resetToken,
      password: "",
      confirmPassword: ""
  });

  const { resetToken, password, confirmPassword } = formData;

  const validationRules = {
    
    password: [
      {
        rule: () => password === confirmPassword,
        message: 'Cele doua parole nu corespund'
      },
      {
        rule: () => /[A-Z]/.test(password),
        message: 'Parola trebuie sa contina o litera mare.'
      },
      {
        rule: () => /\d/.test(password),
        message: 'Parola trebuie sa contina o cifra.'
      },
      {
        rule: v => v.length > 7,
        message: 'Parola trebuie sa aiba minim 8 caractere.'
      }
    ]
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateForm(formData, validationRules);

    if (password.trim() !== confirmPassword.trim() ) {
      showNotification("Cele doua parole trebuie sa corespunda.", "error");
      return;
    }
    if (resetToken.trim() === null || password.trim() === null || confirmPassword.trim() === null) {
      showNotification("Lipsesc informatii. Adauga codul din email si noua parola.", "error");
    } else {
      setIsLoading(true);
      api
        .post("/api/auth/change-password", { resetToken, password, confirmPassword })
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            showNotification("Parola a fost schimbata. Poti sa te autentifici.", "success");
            setTimeout(() => {navigate("/login")}, 3000)
            
          } else {
            showNotification("Ne pare rau, nu putem reseta parola pentru acest user.", "error");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showNotification("Ne pare rau, a intervenit o eroare si nu putem reseta parola pentru acest user.", "error");
        });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  useEffect(() => {
    validateForm(formData, validationRules);
  }, [formData]);


    return (
        <>
            <h2 className="underline decoration-[#02CDB2] decoration-3 underline-offset-8 tracking-wider text-center text-2xl font-sans font-bold tracking-tight text-white">
                Schimba parola
            </h2>
            <div className="">
              <div className="text-white mx-auto w-[90%] text-center mt-[40px]">
                  Verifica adresa de email si introdu codul primit daca nu e deja completat.<br/>
                  Introdu o parola noua.
              </div>
              <div className="w-[90%] mx-auto">
                <form className="mt-8 space-y-6 w-full" onSubmit={handleSubmit}>
                  <TextInput
                    type="text"
                    name="resetToken"
                    required={true}
                    onChange={handleChange}
                    value={resetToken}
                    label="Cod validare"
                    errors={errors}
                    mt={marginTop}
                  >
                    <CheckIcon />
                  </TextInput>
                  <Spacer mb="30" mt="30" />
                  <TextInput
                    type="password"
                    name="password"
                    required={true}
                    onChange={handleChange}
                    value={password}
                    label="Parola noua"
                    errors={errors}
                    mt={marginTop}
                  >
                    <KeyIcon />
                  </TextInput>
                  <TextInput
                    type="password"
                    name="confirmPassword"
                    required={true}
                    onChange={handleChange}
                    value={confirmPassword}
                    label="Confirma parola"
                    errors={errors}
                    mt={marginTop}
                  >
                    <KeyIcon />
                  </TextInput>
                    <FormButton mb="-10" mt="50" label="SCHIMBA PAROLA" theme="green" dataTestId="login-button" style={submitButtonStyle} isLoading={isLoading}/>
                    <div className="w-full text-center ">
                      <Link to="/login" className="text-[#02CDB3] hover:underline">
                          Ti-ai amintit parola?
                      </Link>
                    </div>
                </form>
                <Spacer mb="30" mt="30" />
              </div>
            </div>
        </>
    );
}

export default withValidation(ResetPassword);

import React from 'react';

export const withValidation = (WrappedComponent) => {

    return (props) => {

        const [valid, setValid] = React.useState(false);
        const [errorDisplay, setErrorDisplay] = React.useState(<></>);

        const [errors, setErrors] = React.useState([]);

        const validateForm = (formData, validationRules) => {
            let newErrors = []; // perform your validation logic and populate this array

            for (let fieldName in validationRules) {
                let fieldRules = validationRules[fieldName];
            
                if (!Array.isArray(fieldRules)) {
                  fieldRules = [fieldRules];
                }
            
                for (let ruleObj of fieldRules) {
                  if (!ruleObj.rule(formData[fieldName])) {
                    
                    newErrors.push({
                        field: fieldName,
                        message: ruleObj.message
                    });
                  }
                }
              }
            
            setErrors(newErrors);
            if(newErrors.length > 0) {
                setValid(false);
            } else {
                setValid(true);
            }
        };

        return (
            
                <WrappedComponent
                    {...props} 
                    validateForm={validateForm} 
                    valid={valid} 
                    errors={errors}
                    errorDisplay={errorDisplay}
                />
            
        );
    };
};


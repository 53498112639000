//@TODO: !important - edit date field
//@TODO: Add a route to get all items for a list directly - /lists/ID
//@TODO: Investigate how to get Lists only when the modals are shown/open
//@TODO: Build newly added item with all the data computed on the server: website, image etc
//@TODO: add https:// to the url if it doesn't have one
//@TODO: fix datetime rendering on show and edit
//@TODO: fix missing image icon when no url is provided or no image is found for that url
//@TODO: when the session expires while on the details screen, edit inline etc, and they hit save, they should be redirected to the login page or at least shown an error toaster
//TODO: abstract if (e.target.name === 'uniqueURL' && e.target.value.length > 2) away in both files where it is used - availability logic and used components
//@TODO: abstract updateItem from here and InlineEditor.js
//@TODO refresh lists data after various actions: delete list, edit list, add item, delete item, edit item etc

import { Fragment, useState, useEffect, useContext } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { InlineEditor } from '../Utils/InlineEditor';
import { ReactComponent as CloseSlider } from '../Utils/ui-components/Icons/CloseAddList.svg';
import { TextInput } from '../Utils/ui-components/TextInput';
import { ItemCard } from './ItemCard';
import { FormButton } from '../Utils/ui-components/FormButton';
import { Spacer } from '../Utils/ui-components/Spacer';
import { Switch } from '@headlessui/react';
import Confirmation from '../Utils/Dialog';
import PhotoDialog from '../Utils/PhotoDialog';
import { NotificationContext } from '../Utils/contexts/NotificationContext';

import axios from 'axios';
import {
  purpleGreenGradient,
} from '../../Utils/constants';

export const ListDetails = (props) => {
  const closeModal = () => {
    props.setOpenModal(false);
    // setEditingName(false);
  };
  const [formData, setFormData] = useState({
    itemName: '',
    URL: '',
  });
  
  const { showNotification } = useContext(NotificationContext);
  const [itemsList, setItemsList] = useState([]);
  const [mainEnabled, setMainEnabled] = useState(props.list.main);
  const [publicEnabled, setPublicEnabled] = useState(props.list.public);
  const [openModal, setOpenModal] = useState(false);
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [cancelItem, setCancelItem] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const { itemName, URL } = formData;
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formatURL = url => {
    // Check if the URL starts with "http://" or "https://"
    console.log('HERE', url.trim());
    if(url.trim() === '') {
      return null;
    }
    if (!/^https?:\/\//i.test(url)) {
      // If not, prepend "https://"
      url = "https://" + url;
    }
    return url;
  }

  const updateItemsInfo = (itemId, fieldName, value) => {
    let updatedList = itemsList.map(item => {
      if(item.id === itemId || item.uuid === itemId) {
        return {...item, [fieldName]: value}
      } else {
        return item;
      }
    });
    setItemsList(updatedList);
  }

  const token = localStorage.getItem('jwtToken');

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  useEffect(() => {
    if (!props.visitor) {
      getListItems();

      return () => {
        console.log('Component will unmount!');
      };
    }
  }, []);

  const updateItem = (field, value) => {
    api
      .put(
        `/api/lists/update/${props.list.id}`,
        {
          [field]: value,
        },
        { headers: { Authorization: `${token}` } }
      )
      .then((res) => {
        if (res.status === 204) {
          props.updateListInfo(props.list.id, field, value);
        } else {
          //console.log('ERROR STATUS');
        }
      })
      .catch((err) => {
        //console.log('CAUGHT ERROR');
      });
  };

  const getListItems = () => {
    api
      .post(`/api/lists/get-items/${props.list.id}`, null, {
        headers: { Authorization: `${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setItemsList(res.data);
        }
      })
      .catch((err) => {
        // navigate('/login');
        showNotification("Something went wrong getting the lists 5", "error");
      });
  };

  const addItem = () => {
    setIsLoading(true);
    api
      .post(
        '/api/items/add',
        {
          itemName,
          URL: formatURL(URL),
          listId: props.list.id,
        },
        { headers: { Authorization: `${token}` } }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.status === 201) {
          showNotification("Item creation successful", "success");

          setFormData({
            itemName: '',
            URL: '',
          });

          const newItems = itemsList;
          const newData = formData;
          newData.name = itemName;
          newData.URL = URL;
          newData.id = res.data.id;
          newData.image = res.data.image;
          newData.active = 1;
          newData.available = 1;
          newData.main = 0;
          newItems.push(newData);
          setItemsList(newItems);


          props.updateListInfo(props.list.id, 'itemNo', props.list.itemNo + 1);
        } else {
          setIsLoading(false);
          showNotification("Error adding item.", "error");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification("Error adding item.", "error");
      });
  };

  const deleteItem = (id) => {
    if (id) {
      setIsDeleting(true);
      api
        .delete(`/api/items/delete/${id}`, {
          headers: { Authorization: `${token}` },
        })
        .then((res) => {
          setIsDeleting(false);
          switch (res.status) {
            case 204:
              showNotification("Item deleted successfully", "success");
              props.updateListInfo(props.list.id, 'itemNo', props.list.itemNo - 1);
              setItemsList(itemsList.filter(item => item.id !== id)); 
              break;

            case 401:
              //   navigate('/login');
              setIsDeleting(false);
              break;

            default:
              break;
          }
        });
    }
  };

  const cancelReserve = (id) => {
    setOpenModal(true);
    setCancelItem(id);
  }

  const cancelBuy = (id) => {
    setOpenBuyModal(true);
    setCancelItem(id);
  }

  const cancelReserveCB = () => {
    if (cancelItem) {
      api
        .post(`/api/items/unreserve/${cancelItem}`, null,{
          headers: { Authorization: `${token}` },
        })
        .then((res) => {
          switch (res.status) {
            case 204:
            case 200:
              showNotification("Rezervare anulata cu succes!", "success");
              updateItemsInfo(cancelItem, 'active', 1);
              //props.updateListInfo();
              break;

            case 401:
              //   navigate('/login');
              break;

            default:
              break;
          }
        });
    }
  }

  const cancelBuyCB = () => {
    if (cancelItem) {
      api
        .post(`/api/items/unbuy/${cancelItem}`,null, {
          headers: { Authorization: `${token}` },
        })
        .then((res) => {
          switch (res.status) {
            case 204:
            case 200:
              showNotification("Cumparare anulata cu succes!", "success");
              //props.updateListInfo();
              updateItemsInfo(cancelItem, 'available', 1);
              break;

            case 401:
              //   navigate('/login');
              break;

            default:
              break;
          }
        });
    }
  }
  return (
    <>
      <Confirmation
          executeAction={cancelReserveCB}
          openModal={openModal}
          setOpenModal={setOpenModal}
          title="Reseteaza starea dorintei - Rezervare"
          warning='Cineva a marcat dorinta ta ca si "Rezervata". Asta inseamna ca isi doreste sa o cumpere pentru tine.Esti sigur ca vrei sa anulezi asta?'
          labels={{
            confirm: "Da, anuleaza rezervarea.",
            cancel: "Inchide"
          }}
        />
      <Confirmation
          executeAction={cancelBuyCB}
          openModal={openBuyModal}
          setOpenModal={setOpenBuyModal}
          title="Reseteaza starea dorintei - Cumparare"
          warning='Cineva a marcat dorinta ta ca si "Cumparata". E posibil sa o fi cumparat deja. Esti sigur ca vrei sa anulezi asta?'
          labels={{
            confirm: "Da, anuleaza.",
            cancel: "Inchide"
          }}
        />

        <PhotoDialog
          item={currentItem}
          executeAction={() => {}}
          openModal={openPhotoModal}
          setOpenPhotoModal={setOpenPhotoModal}
          updateItemsList={updateItemsInfo}
          title="Foloseste camera sau adauga o imagine."
          warning=''
          labels={{
            cancel: "Inchide"
          }}
        />  

      <Transition appear show={props.openModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  style={{ background: purpleGreenGradient }}
                  className="sm:w-full md:w-full lg:w-2/3 xl:w-3/5 2xl:w-2/5 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                >
                  <div className="flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-lg flex font-medium leading-6 text-gray-900"
                    ></Dialog.Title>
                    <CloseSlider
                      onClick={closeModal}
                      className="cursor-pointer mt-[-20px] mr-[-20px] text-grey-500 hover:text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="socialButtons text-white relative before:rounded-[15px] items-center justify-center before:pointer-events-none before:absolute before:block before:h-full before:w-full before:top-0 before:left-0 before:content-[''] before:bg-white/70 before:mix-blend-soft-light p-[10px] rounded-2xl ">
                    <span className="text-2xl" data-testid={`listName-${props.list.id}`}>
                      <InlineEditor
                        fieldName={`name`}
                        data={props.list}
                        updateListInfo={props.updateListInfo}
                      />
                    </span>
                    <InlineEditor
                      fieldName={`eventLocation`}
                      data={props.list}
                      updateListInfo={props.updateListInfo}
                    />
                    <InlineEditor
                      fieldName={`eventType`}
                      data={props.list}
                      updateListInfo={props.updateListInfo}
                      type="select"
                    />
                    <InlineEditor
                      fieldName={`eventDatetime`}
                      type="datetime"
                      data={props.list}
                      updateListInfo={props.updateListInfo}
                      customClass='datetime'
                    />
                    <InlineEditor
                      fieldName={`uniqueURL`}
                      data={props.list}
                      updateListInfo={props.updateListInfo}
                      showAvailabilityCheck={true}
                    />
                    <div>
                      <Switch
                        data-testid="switch-main"
                        checked={mainEnabled}
                        onChange={(value) => {
                          setMainEnabled(value);
                          updateItem('main', value);
                        }}
                        className={`${
                          mainEnabled ? 'bg-[#00D1AA]' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full mr-[15px]`}
                      >
                        <span className="sr-only">Lista principala</span>
                        <span
                          className={`${
                            mainEnabled ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                      </Switch>
                      Lista principala a profilului tau
                    </div>
                    <div className="mt-[5px]">
                      <Switch
                        data-testid="switch-public"
                        checked={publicEnabled}
                        onChange={(value) => {
                          setPublicEnabled(value);
                          updateItem('public', value);
                        }}
                        className={`${
                          publicEnabled ? 'bg-[#00D1AA]' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full mr-[15px]`}
                      >
                        <span className="sr-only">Lista publica</span>
                        <span
                          className={`${
                            publicEnabled ? 'translate-x-6' : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                      </Switch>
                      Lista publica
                    </div>
                  </div>

                  <div className="mt-6 mb-3">
                    <div className="text-sm text-white">
                      <h3 className="text-2xl">Adauga dorinte in lista.</h3>
                      Daca ai un link
                      <em>
                        (ex:
                        https://www.emag.ro/telefoane-mobile/brand/apple/c?cmpid=83409)
                      </em>
                      , adauga-l, ii va ajuta pe ceilalti sa iti gaseasca cadoul.
                    </div>
                  </div>
                  <Spacer mb="20" mt="30" />
                  <TextInput
                    type="text"
                    name="itemName"
                    placeholder="iPhone 14"
                    required={true}
                    onChange={handleChange}
                    value={itemName}
                    label="Dorinta / Descriere"
                    style={{ paddingLeft: '8px' }}
                  ></TextInput>
                  <TextInput
                    type="text"
                    name="URL"
                    placeholder="https://..."
                    required={true}
                    onChange={handleChange}
                    value={URL}
                    label="Link (optional)"
                    style={{ paddingLeft: '8px' }}
                    mt="10"
                  ></TextInput>
                  <FormButton
                    onClick={addItem}
                    mb="-10"
                    mt="28"
                    label="Adauga dorinta"
                    style={{ paddingLeft: '5px', paddingRight: '5px' }}
                    theme="green"
                    isLoading={isLoading}
                  />
                  <Spacer mb="20" mt="30" />
                  {itemsList &&
                    itemsList.map((item) => (
                      <ItemCard
                        key={item.id}
                        deleteItem={deleteItem}
                        cancelReserve={cancelReserve}
                        cancelBuy={cancelBuy}
                        setCurrentItem={setCurrentItem}
                        setOpenPhotoModal={setOpenPhotoModal}
                        data={item}
                        isDeleting={isDeleting}
                        updateItemsInfo={updateItemsInfo}
                      />
                    ))}
                  <div className="mt-4 mb-20">
                    <FormButton
                      onClick={closeModal}
                      mb="-10"
                      mt="28"
                      label="Salveaza modificarile"
                      style={{ paddingLeft: '5px', paddingRight: '5px' }}
                      theme="green"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

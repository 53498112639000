import { useState, useEffect, useContext } from 'react';
import { XMarkIcon, PencilIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { NotificationContext } from './contexts/NotificationContext';
import { eventTypes } from '../../Utils/constants';
import { FormButton } from './ui-components/FormButton';
import CheckAvailability from '../Utils/CheckAvailability';
import { convertToDatetimeLocal, formatDate } from '../../Utils';

export function InlineEditor(props) {
  const token = localStorage.getItem('jwtToken');
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  const navigate = useNavigate();

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(props.data[props.fieldName]);
  const [showAvailabilityCheck, setShowAvailabilityCheck] = useState(false);
  const [availabilityColor, setAvailabilityColor] = useState('');
  const { showNotification } = useContext(NotificationContext);
  const handleChange = (e) => {
    setValue(e.target.value);
    if (e.target.name === 'uniqueURL' && e.target.value.length > 2) {
      api
        .post(
          '/api/lists/check-url',
          {
            url: e.target.value,
            id: props.data.id
          },
          { headers: { Authorization: `${token}` } }
        )
        .then((res) => {
          if (res.status === 200) {
            setShowAvailabilityCheck(true);
            if (res.data.msg) {
              setAvailabilityColor('green');
            } else {
              setAvailabilityColor('red');
            }
          } else {
            setShowAvailabilityCheck(true);
            setAvailabilityColor('red');
          }
        })
        .catch((err) => {
          setShowAvailabilityCheck(true);
          setAvailabilityColor('red');
        });
    } else if (e.target.name === 'uniqueURL' && e.target.value.length <= 2) {
      setShowAvailabilityCheck(false);
    }
  };

  const showEdit = () => {
    setEditing(true);
  };

  const closeEdit = () => {
    setEditing(false);
    setValue(props.data[props.fieldName]);
  };

  const updateItem = () => {
    if (!value && props.type !== 'select') return false;
    api
      .put(
        `/api/lists/update/${props.data.id}`,
        {
          [props.fieldName]: value,
        },
        { headers: { Authorization: `${token}` } }
      )
      .then((res) => {
        if (res.status === 204) {
          closeEdit();
          props.updateListInfo(props.data.id, props.fieldName, value);
        } else {
          console.log('ERROR STATUS');
          // case 401:
          //     console.log('REDIRECT ');
          //     navigate('/login');
          //     break;
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('user');
          navigate('/login');
        } else {
          
          showNotification(
            'A aparut o eroare. Incercati sa reincarcati pagina.',
            'error'
          );
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        }
      });
  };

  useEffect(() => {
    setValue(props.data[props.fieldName]);
  }, [props.data, props.fieldName]);

  return (
    <div className="flex mb-[5px] relative">
      {!!props.type && props.type === 'datetime' && (
        <div>{!editing && formatDate(props.data[props.fieldName])}</div>
      )}
      {props.type !== 'datetime' && (
        <div>{!editing && props.data[props.fieldName]}</div>
      )}
      {editing && (
        <>
          <XMarkIcon
            onClick={closeEdit}
            className="cursor-pointer mx-1 mt-3 h-7 w-7 text-grey-500 hover:text-red-400"
            aria-hidden="true"
          />
          {!props.type && (
            <input
              type="text"
              data-testid="inlineInput"
              placeholder="Name"
              name={props.fieldName}
              value={value}
              onChange={handleChange}
              className="relative block w-full rounded-[25px] border-0 py-0 pl-1 mt-[5px] mr-[5px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pr-[40px]"
              required
            />
          )}
          {props.type === 'datetime' && (
            <input
              type="datetime-local"
              data-testid="inlineInput"
              placeholder="Name"
              name={props.fieldName}
              value={convertToDatetimeLocal(value)}
              onChange={handleChange}
              id={props.customClass}
              className="relative block w-full rounded-[25px] border-0 pl-[42px] py-0 px-1 mt-[5px] mr-[5px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              required
            />
          )}
          {props.type === 'select' && (
            <select
              value={value}
              onChange={handleChange}
              className="block mr-[5px] mt-[5px] pl-[42px] w-[90%] bg-white rounded-[23px] h-[38px] py-1.5 text-[#8301E4] ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              {eventTypes &&
                eventTypes.map((option) => (
                  <option
                    selected={value === option.value}
                    value={option.value}
                  >
                    {option.text}
                  </option>
                ))}
            </select>
          )}
          {props.showAvailabilityCheck && (
            <div className="absolute z-10 right-[54px] top-[8px]">
              <CheckAvailability
                show={showAvailabilityCheck}
                color={availabilityColor}
                className="absolute right-[5px] top-0 mt-2 mr-2"
              />
            </div>
          )}
          <FormButton
            onClick={updateItem}
            dataTestId="updateInlineEdit"
            mt="4"
            width="55px"
            label={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            }
            style={{
              paddingLeft: '7px',
              paddingRight: '0px',
              opacity: value === '' && props.type !== 'select' ? 0.3 : 1,
            }}
            theme="green"
          />
        </>
      )}
      <div>
        {!editing && value && (
          <PencilIcon
            onClick={showEdit}
            className="cursor-pointer mx-1 h-5 w-5 text-slate-400 hover:text-blue-400 text-[#4C0184] stroke-[#02CDB3]"
            aria-hidden="true"
            data-testid="pencilIcon"
          />
        )}
        {!editing && !value && (
          <span
            onClick={showEdit}
            className="cursor-pointer mx-1 h-5 w-5 text-slate-400 hover:text-blue-400 text-[#4C0184]"
          >
            [Adauga categoria listei]
          </span>
        )}
      </div>
    </div>
  );
}

export function formatDate(dateString) {
  const date = new Date(`${dateString}`);
  const options = { day: 'numeric', month: 'short', year: '2-digit', hour: 'numeric', minute: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-GB', options);
  return formattedDate;
}

export function convertToDatetimeLocal(isoString) {
  const date = new Date(isoString);
  // get individual components
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed
  let day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');
  
  // assemble and return the datetime-local string
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
// convertToDatetimeLocal 2023-04-19 23:42:00
// convertToDatetimeLocal END 2023-04-19T20:42
export const googleLogin = () => {
  window.location.href = process.env.REACT_APP_API_BASE_URL + 'api/auth/google/';
}

export const discordLogin = () => {
  window.location.href = process.env.REACT_APP_API_BASE_URL + 'api/auth/discord/';
}

export function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return JSON.parse(cookie.substring(name.length + 1));
    }
  }
  return null;
}

export function setCookie(name, value, days) {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = name + '=' + JSON.stringify(value) + ';expires=' + expires.toUTCString() + ';path=/';
}

export function addItemToCookie(name, itemDetails, days) {
  const reservedCookie = getCookie(name);
  let data = [];
  if (reservedCookie) {
    // Check for duplicate combination of listId and itemId
    const isDuplicate = reservedCookie.some(item => item.listId === itemDetails.listId && item.itemId === itemDetails.itemId);
    if (!isDuplicate) {
      data = reservedCookie.concat(itemDetails);
    } else {
      // Duplicate combination, do not add the item
      data = reservedCookie;
    }
  } else {
    data.push(itemDetails);
  }
  
  setCookie(name, data, days);
}

export function removeItemFromCookie(name, itemId) {
  const reservedCookie = getCookie(name);

  if (reservedCookie) {
    const updatedData = reservedCookie.filter(item => item.itemId !== itemId);
    setCookie(name, updatedData);
  }
}

// Function to save an object in local storage array
export function saveObjectToLocalArray(arrayName, obj) {
  // Retrieve existing array from local storage or create a new empty array
  let array = JSON.parse(localStorage.getItem(arrayName)) || [];
  // Check if object with the same uuid already exists in the array
  const existingObj = array.find(item => item.item === obj.item);
  if (existingObj) {
    console.log('Object with the same uuid already exists in the array.');
    return;
  }

  // Add the object to the array
  array.push(obj);
  // Save the updated array back to local storage
  localStorage.setItem(arrayName, JSON.stringify(array));
}

// Function to remove an object from local storage array by uuid
export function removeObjectFromLocalArray(arrayName, uuid) {
  // Retrieve existing array from local storage
  let array = JSON.parse(localStorage.getItem(arrayName)) || [];

  // Find the index of the object with the provided uuid
  const index = array.findIndex(item => item.item === uuid);
  if (index === -1) {
    console.log('Object with the provided uuid does not exist in the array.');
    return;
  }

  // Remove the object from the array
  array.splice(index, 1);

  // Save the updated array back to local storage
  localStorage.setItem(arrayName, JSON.stringify(array));
}

export function searchUuidInLocalArray(arrayName, uuid) {
  // Retrieve the array from local storage
  const array = JSON.parse(localStorage.getItem(arrayName)) || [];

  // Check if any object in the array has the provided uuid
  const foundObject = array.find(item => item.item === uuid);

  // Return true if a matching uuid is found, false otherwise
  return !!foundObject;
}

export function formatName(word, length) {
  if(word.length <= length) {
    return word;
  } else {
    return word.slice(0,length) + '...';
  }
}
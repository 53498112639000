//@TODO refactor and abstract showNotification function + handle the errorMessage vs successMessage logic
import React, { useState, useContext } from "react";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {SocialLogins} from "../Utils/SocialLogins";
import { Spacer } from "../Utils/ui-components/Spacer";
import { TextInput } from "../Utils/ui-components/TextInput";
import { FormButton } from "../Utils/ui-components/FormButton";
import { NotificationContext } from '../Utils/contexts/NotificationContext';

import { ReactComponent as EnvelopeIcon } from "../Utils/ui-components/Icons/Envelope.svg";
import { ReactComponent as KeyIcon } from "../Utils/ui-components/Icons/Key.svg";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const oauthToken = searchParams.get('token');
  if(oauthToken) {
    const newName = searchParams.get('name');
    const avatar = searchParams.get('avatar');
    const id = searchParams.get('id'); 
    const email = searchParams.get('email');
    const profileName = searchParams.get('profileName');
    const newUser = {
      avatar, id, email, profileName, name: newName
    };
    localStorage.setItem("jwtToken", oauthToken);
    localStorage.setItem("user", JSON.stringify(newUser));
    navigate("/lists");
  }

  const { email, password } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.trim() === null || email.trim() === null) {
      showNotification("Enter data.", "error");
    } else {
      setIsLoading(true);
      api
        .post("/api/auth/login", { email, password })
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            
            const token = res.data.token;
            const user = res.data.user;
            localStorage.setItem("jwtToken", token);
            localStorage.setItem("user", JSON.stringify(user));

            navigate("/lists");
          } else {
            showNotification("Auth data incorrect.", "error");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showNotification("Error authenticating user.", "error");
        });
    }
  };

  const marginTop = 20;
  return (
    <>
      <h2 className="underline decoration-[#02CDB2] decoration-3 underline-offset-8 tracking-wider text-center text-2xl font-sans font-bold tracking-tight text-white">
        Login
      </h2>
      <div className="">
        <SocialLogins />
        <Spacer mb="40" mt="50" />
        <div className="text-white mx-auto w-[90%] text-center">
          sau autentifica-te cu adresa de e-mail
        </div>
        <div className="w-[90%] mx-auto">
          <form className="mt-8 space-y-6 w-full" onSubmit={handleSubmit}>
            <TextInput
              type="email"
              name="email"
              placeholder="user@domain.com"
              required={true}
              onChange={handleChange}
              value={email}
              label="Email"
            >
              <EnvelopeIcon />
            </TextInput>

            <TextInput
              type="password"
              name="password"
              placeholder="Parola"
              required={true}
              onChange={handleChange}
              value={password}
              label="Parola"
              mt={marginTop}
            >
              <KeyIcon />
            </TextInput>

            <FormButton mb="-10" mt="50" label="LOGIN" theme="green" dataTestId="login-button" isLoading={isLoading} />
            <div className="w-full text-center ">
              <Link to="/recover" className="text-[#02CDB3] hover:underline">
                  Ai uitat parola?
              </Link>
            </div>
          </form>
          <Spacer mb="30" mt="30" />
          <div className="text-white mx-auto w-[90%] text-center">
            Nu ai cont?
          </div>
          <Link to="/register">
            <FormButton
              mt="10"
              mb="150"
              label="INREGISTREAZA-TE"
              theme="purple"
            />
          </Link>
          <br />
        </div>
      </div>
    </>
  );
};

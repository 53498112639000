import React, { createContext, useState } from 'react';

export const ListsContext = createContext();

export function ListsProvider({ children }) {
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshLists = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  return (
    <ListsContext.Provider value={{ refreshKey, refreshLists }}>
      {children}
    </ListsContext.Provider>
  );
}

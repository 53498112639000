import { ReactComponent as Reserved } from '../Utils/ui-components/Icons/Rezervat.svg';
import { ReactComponent as Bought } from '../Utils/ui-components/Icons/Cumparat.svg';
import { ReactComponent as ReservedSmall } from '../Utils/ui-components/Icons/RezervatSmall.svg';
import { ReactComponent as BoughtSmall } from '../Utils/ui-components/Icons/CumparatSmall.svg';
import { ReactComponent as ReservedXSmall } from '../Utils/ui-components/Icons/RezervatXSmall.svg';
import { ReactComponent as BoughtXSmall } from '../Utils/ui-components/Icons/CumparatXSmall.svg';

export const ReservedLabel = (props) => {
    const size = props.size || 'large';
    let testId = '';
    if(props.bought) {
        testId = 'bought-label';
    } else {
        testId = 'reserved-label';
    }
    if(size === 'large') {
        return (
            <div data-testid={`large-${testId}`} className="absolute top-[0px] left-[0px]">
                {props.bought ? <Bought /> : <Reserved />}
            </div>
        );
    }

    if(size === 'small') {
        return (
            <div data-testid={`small-${testId}`} className="absolute top-[0px] left-[0px]">
                {props.bought ? <BoughtSmall /> : <ReservedSmall />}
            </div>
        );
    }

    if(size === 'xsmall') {
        return (
            <div className="absolute top-[-8px] left-[25px]">
                {props.bought ? <BoughtXSmall /> : <ReservedXSmall />}
            </div>
        );
    }
}
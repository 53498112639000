import { Fragment, useState } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

export default function CheckAvailability(props) {
  return (
    <>
      {props.show && props.color === 'green' && (
        <CheckCircleIcon
          className="h-5 w-5 text-[#01a614] mt-2 ml-1"
          aria-hidden="true"
          data-testid="check-yes"
        />
      )}
      {props.show && props.color === 'red' && (
        <XCircleIcon
          className="h-5 w-5 text-red-500 mt-2 ml-1"
          aria-hidden="true"
          data-testid="check-no"
        />
      )}
    </>
  );
}

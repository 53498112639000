import {useState} from 'react';
import { ReactComponent as ShareProfile } from './ui-components/Icons/ShareProfile.svg';
import { ReactComponent as ShareListIcon } from '../Utils/ui-components/Icons/ShareSmall.svg';
import {Notification} from './ui-components/Notification';
export const ShareButton = props => {

    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    let [isNotificationOpen, setIsNotificationOpen] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(props.url)
            .then(() => {
                showNotification('Adresa a fost copiata, poti face paste.', 'success');
            })
            .catch((error) => console.log('Failed to copy URL', error));
    }

    const showNotification = (message, type) => {
        setNotificationMessage(message);
        setNotificationType(type);
        setIsNotificationOpen(true);
    }

    const handleShare = (e) => {
        e.stopPropagation();
        if (navigator.share) {
            navigator.share({
                title: props.title,
                text: props.text,
                url: props.url,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        } else {
            // Fallback for devices that don't support the Web Share API
            handleCopy();
        }
    }

    return (
        <>
            <Notification
                isOpen={isNotificationOpen}
                type={notificationType}
                message={notificationMessage}
                setIsOpen={setIsNotificationOpen}
            />
            {props.small && (
                <ShareListIcon className='cursor-pointer' onClick={handleShare} />
            )}
            {!props.small && (
                <ShareProfile className='cursor-pointer' onClick={handleShare} />
            )}
        </>
    );
}
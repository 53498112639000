import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ListCard } from '../Lists/ListCard';
import { NotificationContext } from '../Utils/contexts/NotificationContext';
import { CardLoader } from '../Utils/ui-components/CardHolder';
import { useNavigate } from "react-router-dom";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const Profile = () => {
  const params = useParams();
  const [listsList, setListsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const profile = params.profile;
  const { showNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getUserLists = () => {
      api
        .get(`/api/users/profile/${profile}`)
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            
            setListsList(res.data);
          } else {
            showNotification('Something went wrong getting the lists 6', 'error');
          }
        })
        .catch((err) => {
          setIsLoading(false);
          showNotification('Something went wrong getting the lists 7', 'error');
          navigate(`/`);
        });
    };
  
    getUserLists();
    // If you need to clean up after the effect, you can return a function from the effect
    return () => {
      
    };
  }, [profile]);

  const mainList = listsList.find((list) => list.main === 1);
  const otherLists = listsList.filter((list) => list.main === 0);
  
  return (
    <>
      <div className="min-h-[600px]">
        <h2 className="underline decoration-[#02CDB2] decoration-3 underline-offset-8 tracking-wider text-center text-2xl font-sans font-bold tracking-tight text-white">
          @{profile}
        </h2>

        {isLoading && (
          <CardLoader />
        )}
    
        {!isLoading && mainList && (
          <div className="w-full mt-6 grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-1 lg:grid-cols-1 xl:gap-x-8 ">
            <ListCard
              key={mainList.id}
              visitor={true}
              profile={profile}
              updateListInfo={() => {}}
              data={mainList}
            />
          </div>
        )}

        <div className="w-full mt-6 grid grid-cols-2 gap-y-3 gap-x-3 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
          {!isLoading && otherLists &&
            otherLists.map((list) => (
              <ListCard
                key={list.id}
                profile={profile}
                visitor={true}
                data={list}
                updateListInfo={() => {}}
              />
            ))}
        </div>
      </div>
    </>
  );
};

//@TODO refactor checkAvailability logic and move to utils/CheckAvailability.js

import { useState, useEffect, useContext } from 'react';
import { ListsContext } from '../Utils/contexts/ListsContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ListCard } from './ListCard';
import Confirmation from '../Utils/Dialog';
import { CardLoader } from '../Utils/ui-components/CardHolder'
import { NotificationContext } from '../Utils/contexts/NotificationContext';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const Lists = () => {
  const { showNotification } = useContext(NotificationContext);
  const [listsList, setListsList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentList, setCurrentList] = useState();
  const { refreshKey } = useContext(ListsContext);
  const { refreshLists } = useContext(ListsContext);
  const token = localStorage.getItem('jwtToken');
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const openDeleteModal = (id) => {
    setOpenModal(true);
    setCurrentList(id);
  };

  const deleteList = () => {
    const id = currentList;
    if (currentList) {
      api
        .delete(`/api/lists/delete/${id}`, {
          headers: { Authorization: `${token}` },
        })
        .then((res) => {
          switch (res.status) {
            case 204:
              showNotification("List deleted successfully", "success");
              refreshLists();
              break;

            case 401:
              navigate('/login');
              break;

            default:
              break;
          }
        }).catch(e => {
          navigate('/login');
        });
    }
  };

  const getUserLists = () => { 
    api
      .post('/api/users/lists', null, {
        headers: { Authorization: `${token}` },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setListsList(res.data);
        } else {
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('user');
          navigate('/login');
          showNotification("Something went wrong getting the lists 1", "error");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('user');
        navigate('/login');
        showNotification("Something went wrong getting the lists 2", "error");
      });
  };

  const updateUserLists = (listId, fieldName, value) => {
    let updatedList = listsList.map(item => {
      if(item.id === listId) {
        return {...item, [fieldName]: value}
      } else {
        return item;
      }
    });
    setListsList(updatedList);
  }

  useEffect(() => {
    getUserLists();

    // If you need to clean up after the effect, you can return a function from the effect
    return () => {
      console.log('Component will unmount!');
    };
  }, [navigate, token, refreshKey]);

  const mainList = listsList.find((list) => list.main === 1);
  const otherLists = listsList.filter((list) => list.main === 0);

  return (
    <div className="min-h-[600px]">
      <Confirmation
        executeAction={deleteList}
        openModal={openModal}
        setOpenModal={setOpenModal}
        title="Delete Wishlist"
        warning = "Are you sure you want to delete this wishlist? All its related data will be permanently removed. This action cannot be undone."
        labels={{
          confirm: 'Sterge',
          cancel: 'Anuleaza'
        }}
      />
      <h2 className="underline decoration-[#02CDB2] decoration-3 underline-offset-8 tracking-wider text-center text-2xl font-sans font-bold tracking-tight text-white">
        Listele tale
      </h2>

      {isLoading && (
        <CardLoader />
      )}

      {!isLoading && mainList && (
        <div className="w-full mt-6 grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-1 lg:grid-cols-1 xl:gap-x-8 ">
          <ListCard
            openDeleteModal={openDeleteModal}
            updateListInfo={updateUserLists}
            data={mainList}
          />
        </div>
      )}

      <div className="w-full mt-6 grid grid-cols-2 gap-y-3 gap-x-3 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
        {!isLoading && otherLists &&
          otherLists.map((list) => (
            <ListCard
              key={`listcard${list.id}`}
              openDeleteModal={openDeleteModal}
              data={list}
              updateListInfo={updateUserLists}
            />
          ))}
      </div>
    </div>
  );
};

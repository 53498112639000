import { Routes, Route, Link } from 'react-router-dom';
import { Layout } from './components/Layout';
import { LayoutSimple } from './components/Layout/simple';
import { Home } from './components/Home';
import { Login } from './components/Login';
import Recover from './components/Recover';
import ResetPassword from './components/Recover/ResetPassword';
import { Logout } from './components/Logout';
import { Lists } from './components/Lists';
import { List } from './components/Lists/List';
import { Profile } from './components/Profile';
import UserProfile from './components/UserProfile';
import Register from './components/Register';
import { ListsProvider } from './components/Utils/contexts/ListsContext';
import { NotificationProvider } from './components/Utils/contexts/NotificationContext';

// import logo from './logo.svg';
import './App.css';
import './styles.css';
export default function App() {
  return (
    <ListsProvider>
      <Routes>
        <Route index element={
            <Layout bgImg="bg-login.png">
              <Home />
            </Layout>
          } />
        <Route
          path="profile/:profile"
          element={
            <LayoutSimple profile={true}>
              <NotificationProvider>
                <Profile />
              </NotificationProvider>
            </LayoutSimple>
          }
        />
        <Route
          path="profile/:profile/:listUrl"
          element={
            <LayoutSimple list={true} profile={true}>
              <NotificationProvider>
                <List />
              </NotificationProvider>
            </LayoutSimple>
          }
        />
        <Route
          path="register"
          element={
            <Layout bgImg="bg-register.png">
              <NotificationProvider>
                <Register />
              </NotificationProvider>
            </Layout>
          }
        />
        <Route
          path="lists"
          element={
            <LayoutSimple profile={false}>
              <NotificationProvider>
                <Lists />
              </NotificationProvider>
            </LayoutSimple>
          }
        />
        <Route
          path="lists/create"
          element={
            <LayoutSimple create={true} profile={false}>
              <NotificationProvider>
                <Lists />
              </NotificationProvider>
            </LayoutSimple>
          }
        />
        <Route
          path="login"
          element={
            <Layout bgImg="bg-login.png">
              <NotificationProvider>
                <Login />
              </NotificationProvider>
            </Layout>
          }
        />
        <Route
          path="recover"
          element={
            <Layout bgImg="bg-login.png">
              <NotificationProvider>
                <Recover />
              </NotificationProvider>
            </Layout>
          }
        />
        <Route
          path="recover/reset-password"
          element={
            <Layout bgImg="bg-login.png">
              <NotificationProvider>
                <ResetPassword />
              </NotificationProvider>
            </Layout>
          }
        />
        <Route
          path="recover/reset-password/code/:resetToken"
          element={
            <Layout bgImg="bg-login.png">
              <NotificationProvider>
                <ResetPassword />
              </NotificationProvider>
            </Layout>
          }
        />
        <Route
          path="user-profile"
          element={
            <LayoutSimple profile={false}>
              <NotificationProvider>
                <UserProfile />
              </NotificationProvider>
            </LayoutSimple>
          }
        />
        <Route path="logout" element={<NotificationProvider><Logout /></NotificationProvider>} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </ListsProvider>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

// export default App;

//@TODO - move reserve/buy and all deletes from id's based on actual columns to generated id keys that cannot be guessed Ex: /api/items/reserve/23e23d23d23ed23d23
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { PublicItemCard } from './PublicItemCard';
import { ListContext } from '../Utils/contexts/ListContext';
import Confirmation from '../Utils/Dialog';
import { saveObjectToLocalArray, removeObjectFromLocalArray } from '../../Utils';
import { RESERVE_EXPIRATION } from '../../Utils/constants';
import { CardLoader } from '../Utils/ui-components/CardHolder';
import { NotificationContext } from '../Utils/contexts/NotificationContext';
import { useNavigate } from "react-router-dom";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const List = () => {
  const { showNotification } = useContext(NotificationContext);
  const { list, setList } = useContext(ListContext);
  const [mainItemId, setMainItemId] = useState();
  const [items, setItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openBuyModalFlag, setOpenBuyModalFlag] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const params = useParams();
  
  const openReserveModal = (item) => {
    setOpenModal(true);
    setCurrentItem(item);
  };

  const openBuyModal = (item) => {
    setOpenBuyModalFlag(true);
    setCurrentItem(item);
  };

  const reserveItem = () => {
    const id = currentItem.uuid;
    if (currentItem) {
      api
        .post(`/api/items/${ currentItem.active ? 'reserve' : 'unreserve' }/public/${id}`)
        .then((res) => {
          switch (res.status) {
            case 204:
            case 200:

              if(currentItem.active) {
                showNotification("Dorinta a fost rezervata", "success");
              } else {
                showNotification("Dorinta nu mai este rezervata", "success");
              }
              if(currentItem.active) {
                const currentDate = new Date();
                saveObjectToLocalArray('listItem', {item: currentItem.uuid, expires: currentDate.getTime() + RESERVE_EXPIRATION * 24 * 3600 * 1000});
              } else {
                removeObjectFromLocalArray('listItem', currentItem.uuid);
                removeObjectFromLocalArray('buyListItem', currentItem.uuid);
              }

              const updatedItems = items.map(item => {
                if (item.uuid === id) {
                  return { ...item, active: currentItem.active ? 0 : 1, available: !currentItem.active ? 1 : item.available };
                }
                return item;
              });
              setItems(updatedItems);
              break;

            case 500:
              break;

            default:
              break;
          }
        });
    }
  }

  const buyItem = () => {
    const id = currentItem.uuid;
    if (currentItem) {
      api
        .post(`/api/items/${ currentItem.available ? 'buy' : 'unbuy' }/public/${id}`)
        .then((res) => {
          switch (res.status) {
            case 204:
            case 200:
              if(currentItem.available) {
                showNotification("Dorinta a fost marcata ca si cumparata", "success");
              }

              if(!currentItem.available) {
                showNotification("Dorinta nu mai este marcata ca si cumparata", "success");
              }

              if(currentItem.available) {
                const currentDate = new Date();
                saveObjectToLocalArray('buyListItem', {item: currentItem.uuid, expires: currentDate.getTime() + RESERVE_EXPIRATION * 24 * 3600 * 1000});
              } else {
                removeObjectFromLocalArray('buyListItem', currentItem.uuid);
              }

              const updatedItems = items.map(item => {
                if (item.uuid === id) {
                  return { ...item, available: currentItem.available ? 0 : 1 };
                }
                return item;
              });
          
              setItems(updatedItems);
              break;

            case 500:
              break;

            default:
              break;
          }
        });
    }
  }
  
  const getList = () => {
   
    api
      .get(`/api/list/${params.profile}/${params.listUrl}`)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setList(res.data);
          setItems(res.data.items);
          const mainItem = res.data.items.find((item) => item.main === 1);
          if(mainItem) {
            setMainItemId(mainItem.id);
          }
        } else {
          showNotification('Something went wrong getting the lists 3', 'error');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification('Something went wrong getting the lists 4', 'error');
        navigate(`/profile/${params.profile}`);
      });
  };

  useEffect(() => {
    // if (props.list) {
    getList();
    // }

    // If you need to clean up after the effect, you can return a function from the effect
    return () => {
      console.log('Component will unmount!');
    };
  }, []);

    const linkedItemsWithImages = items.filter((item) => (item.url !== null || item.image !== null) && item.main !== 1);
    const restOfTheItems = items.filter((item) => item.image === null && item.url === null && item.main !== 1);

    let confirmationTitle = '', confirmationMessage = '', confirmButtonLabel = '', cancelButtonLabel = '';
    let buyConfirmationTitle = '', buyConfirmationMessage = '', buyConfirmButtonLabel = '', buyCancelButtonLabel = '';

    if(currentItem && currentItem.active) {
      confirmationTitle = 'Rezerva dorinta pentru 24 de ore';
      confirmationMessage = 'Esti sigur(a) ca vrei sa rezervi dorinta? Timp de 24 de ore nimeni nu o mai poate rezerva. Poti sa o marchezi din nou ca nerezervata, de pe acelasi device de unde ai rezervat-o.';
      confirmButtonLabel = 'Rezerva';
      cancelButtonLabel = 'Renunta';
    } else {
      confirmationTitle = 'Anuleaza rezervarea';
      confirmationMessage = 'Esti sigur(a) ca vrei sa anulezi rezervarea dorintei?';
      confirmButtonLabel = 'Anuleaza';
      cancelButtonLabel = 'Inchide';
    }

    if(currentItem && currentItem.available) {
      buyConfirmationTitle = 'Marcheaza dorinta ca si cumparata';
      buyConfirmationMessage = 'Esti sigur(a) ca vrei sa marchezi dorinta ca si cumparata? Timp de 24 de ore nimeni nu o mai poate marca. Poti sa o marchezi din nou ca necumparate, de pe acelasi device de unde ai rezervat-o.';
      buyConfirmButtonLabel = 'Marcheaza ca si cumparata';
      buyCancelButtonLabel = 'Renunta';
    } else {
      buyConfirmationTitle = 'Anuleaza marcarea ca si cumparata';
      buyConfirmationMessage = 'Esti sigur(a) ca vrei sa anulezi cumpararea dorintei?';
      buyConfirmButtonLabel = 'Anuleaza';
      buyCancelButtonLabel = 'Inchide';
    }
  return (
    <>
      <Confirmation
        executeAction={reserveItem}
        openModal={openModal}
        setOpenModal={setOpenModal}
        title={confirmationTitle}
        warning={confirmationMessage}
        labels={{
          confirm: confirmButtonLabel,
          cancel: cancelButtonLabel
        }}
      />
      <Confirmation
        executeAction={buyItem}
        openModal={openBuyModalFlag}
        setOpenModal={setOpenBuyModalFlag}
        title={buyConfirmationTitle}
        warning={buyConfirmationMessage}
        labels={{
          confirm: buyConfirmButtonLabel,
          cancel: buyCancelButtonLabel
        }}
      />
      
      {isLoading && (
        <CardLoader />
      )}

      {!isLoading && mainItemId && (
        <div className="w-full mt-6 grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-1 lg:grid-cols-1 xl:gap-x-8 ">
          <PublicItemCard openReserveModal={openReserveModal} openBuyModal={openBuyModal} item={items.find((item) => item.id === mainItemId)} />
        </div>
      )}
      <div className="w-full mt-6 grid grid-cols-2 gap-y-3 gap-x-3 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
        {!isLoading && linkedItemsWithImages.length && linkedItemsWithImages.map((item) => <PublicItemCard key={`list-item${item.id}`} openReserveModal={openReserveModal} openBuyModal={openBuyModal} small={true} item={item} />)}
      </div>

      <div className="w-full mt-6 grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
        {!isLoading && restOfTheItems.length && restOfTheItems.map((item) => <PublicItemCard key={`list-item${item.id}`} openReserveModal={openReserveModal} openBuyModal={openBuyModal} plain={true} item={item} />)}
      </div>
    </>
  );
};

//@TODO: update the items No after add or delete
//@TODO: add share functionality - on desktop just copy URL
//@TODO: add a security mechanism to prevent users from accessing other users lists or editing them
//@TODO: make menu dynamic - logout instead of login if user is logged in
//@TODO: make Add List button take you to login/register if you are not logged in

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  CalendarDaysIcon,
  MapPinIcon,
  CakeIcon,
  GiftIcon,
} from '@heroicons/react/20/solid';
import { ReactComponent as DeleteListIcon } from '../Utils/ui-components/Icons/DeleteSmall.svg';
import { lightGradient, mainGradient } from '../../Utils/constants';
import { ListDetails } from './ListDetails';
import { PrivateIcon, PublicIcon } from '../Utils/ui-components/PrivatePublicIcons';
import { ShareButton } from '../Utils/ShareButton';
import { formatDate } from '../../Utils';
import { NotificationProvider } from '../Utils/contexts/NotificationContext';

export const ListCard = (props) => {
  const navigate = useNavigate();

  const removeList = (id) => {
    props.openDeleteModal(id);
  };

  const openDetails = () => {
    setOpenModal(true);
  };

  const clickListCard = () => {
    if (!props.visitor) {
      openDetails();
    } else {
      navigate(`/profile/${props.profile}/${props.data.uniqueURL}`);
    }
  };

  const params = useParams();
  const [openModal, setOpenModal] = useState(false);
  const cardGradient = props.data.main ? mainGradient : lightGradient;
  const sharePositionTop = props.visitor ? '5' : '45';

  let user;
  if(!props.visitor) {
    user = JSON.parse(localStorage.getItem('user'));
  }
  return (
    <>
      
      <NotificationProvider>
        <ListDetails
          list={props.data}
          openModal={openModal}
          setOpenModal={setOpenModal}
          updateListInfo={props.updateListInfo}
          visitor={props.visitor}
        />
      </NotificationProvider>
      <div
        key={`list-${props.data.id}`}
        className="cursor-pointer min-h-32 aspect-w-1 aspect-h-1 w-full overflow-hidden  lg:aspect-none lg:h-33"
        onClick={clickListCard}
        data-testid={`listCard-${props.data.id}-${props.data.main}`}
      >
        <div
          style={{ background: cardGradient }}
          className="relative min-h-[144px] mt-4 flex px-[5px] py-[10px] rounded-[15px] text-[#5800C1]"
        >
          <div>
            <div className="flex text-sm font-light py-[3px]">
              {!!props.data.eventDatetime && (
                <>
                  <CalendarDaysIcon
                    className="mx-1 h-5 w-5 text-[#5800C1] "
                    aria-hidden="true"
                  />
                  {formatDate(props.data.eventDatetime)}
                </>
              )}
            </div>
            <div className="flex text-sm font-light py-[3px]">
              <GiftIcon
                className="mx-1 h-5 w-5 cursor-pointer"
                aria-hidden="true"
              />
              <div>{props.data.itemNo} dorinte</div>
            </div>
            {!!props.data.eventType && (
              <div className="flex mt-1 text-sm font-light py-[3px]">
                <CakeIcon className="mx-1 h-5 w-5" aria-hidden="true" />
                {props.data.eventType}
              </div>
            )}
            {!!props.data.eventLocation && (
              <div className="flex mt-1 text-sm text-[#01aa95] hover:underline font-light py-[3px]">
                <div>
                  <MapPinIcon className="mx-1 h-5 w-5" aria-hidden="true" />
                </div>
                <div className="w-[70%]">
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                      props.data.eventLocation
                    )}`}
                    target="_blank"
                  >
                    {props.data.eventLocation}
                  </a>
                </div>
              </div>
            )}
          </div>
          {!props.visitor && (
            <div
              onClick={(e) => {
                removeList(props.data.id);
                e.stopPropagation();
              }}
              data-testid="remove-list-icon"
              className="absolute text-sm cursor-pointer text-gray-500 right-[5px] top-[5px]"
            >
              <DeleteListIcon />
            </div>
          )}
          {props.visitor && (
            <div className={`absolute text-sm cursor-pointer text-gray-500 right-[5px] top-[5px]`}
            >
              <ShareButton
                small={true}
                url={`${process.env.REACT_APP_BASE_URL}profile/${props.profile}/${props.data.uniqueURL}`}
                title={`Lista lui ${params.profile}`}
                text="Listi.ro - listele tale de dorinte"
              />
            </div>
          )}
          {!props.visitor && !!props.data.public && user &&(
            <div className={`absolute text-sm cursor-pointer text-gray-500 right-[5px] top-[45px]`}
            >
              <ShareButton
                small={true}
                url={`${process.env.REACT_APP_BASE_URL}profile/${user.profileName}/${props.data.uniqueURL}`}
                title={`Lista lui ${params.profile}`}
                text="Listi.ro - listele tale de dorinte"
              />
            </div>
          )}
          {!!props.data.public && !props.visitor &&
            <div data-testid={`publicIcon-${props.data.id}`} className='absolute bottom-[5px] right-[5px] text-[#03aa94]'>
              <PublicIcon />
            </div> }
          {!props.data.public && !props.visitor &&  
            <div data-testid={`privateIcon-${props.data.id}`} className='absolute bottom-[5px] right-[5px]'>
              <PrivateIcon />
            </div>
          }
          
        </div>
        <div className="">
          <h3 className="text-sm text-white ml-[12px] mt-[5px] truncate">
            <Link to={`/profile/${props.profile}/${props.data.uniqueURL}`}>
              {props.data.name}
            </Link>
          </h3>
        </div>
      </div>
    </>
  );
};

import React, { createContext, useState, useCallback } from 'react';
import {Notification} from '../ui-components/Notification';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('');
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const showNotification = useCallback((message, type) => {
    setNotificationMessage(message);
    setNotificationType(type);
    setIsNotificationOpen(true);
  }, []);

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      <Notification 
        message={notificationMessage} 
        type={notificationType} 
        isOpen={isNotificationOpen} 
        setIsOpen={setIsNotificationOpen}
      />
      {children}
    </NotificationContext.Provider>
  );
};

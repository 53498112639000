export const NOTIFICATION_FADE_INTERVAL = 3500;
export const NOTIFICATION_BG_ERROR = '#DB606E';
export const NOTIFICATION_BG_SUCCESS = '#04EA60 ';
export const eventTypes = [
  { text: 'Selecteaza tipul', value: '' },
  { text: 'Niciunul', value: '' },
  { text: 'Aniversare', value: 'aniversare' },
  { text: 'Onomastica', value: 'onomastica' },
  { text: 'Botez', value: 'botez' },
  { text: 'Nunta', value: 'nunta' },
  { text: 'Altul', value: 'altul' },
];
export const RESERVE_EXPIRATION = 1;
export const purpleGradient =
  'linear-gradient(105.07deg, #13CAFF -27.48%, #C84DFF 87.77%)';
export const reversePurpleGradient =
  'linear-gradient(254.93deg, #13CAFF -27.48%, #C84DFF 87.77%)';
export const greenGradient =
  'linear-gradient(13.77deg, #04EA60 -20.49%, #00B2FF 90.92%)';
export const reverseGreenGradient =
  'linear-gradient(346.23deg, #04EA60 -20.49%, #00B2FF 90.92%)';
export const orangeGradient =
  'linear-gradient(180deg, rgba(187,0,255,1) 0%, rgba(234,132,4,1) 100%)';
export const lightGradient =
  'linear-gradient(180deg, #FFFFFF 0%, #DDDDDD 100%)';
export const purpleGreenGradient =
  'linear-gradient(13.77deg, #04EA60 -20.49%, #BB00FF 90.92%)';
export const mainGradient =
  'linear-gradient(180deg, rgb(255 240 0) 0%, rgb(199 255 89) 100%)';

import { Menu } from '../Utils/Menu';
import BottomMenuBar from '../BottomMenuBar';
import { useParams } from 'react-router-dom';
import { ListIntro } from '../Lists/ListIntro';
import { ListContext } from '../Utils/contexts/ListContext';
import { useEffect, useState } from 'react';
import { NotificationProvider } from '../Utils/contexts/NotificationContext';
import axios from 'axios';

export function LayoutSimple(props) {
  const { children } = props;
  const user = localStorage.getItem('user');
  const params = useParams();
  const [list, setList] = useState(0);
  const [avatar, setAvatar] = useState();
  
  let profileName;

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  if (params.profile && props.profile) {
    profileName = params.profile;
  }
  
  const getUserInfo = async () => {
    api
      .get(`/api/users/profile/info/${profileName}`)
      .then(res => {
        console.log("USER INFO", res);
        setAvatar(res.data.avatar);
      }).catch(err => {
        console.log(err);
      });
    ;
  }

  useEffect(() => {
    if (user && !props.profile) {
        console.log('H1');
        profileName = JSON.parse(user).profileName;
        setAvatar(JSON.parse(user).avatar);
      }
    }, [user, props.profile]); 

  useEffect(() => {
    if (params.profile && props.profile) {
      getUserInfo();
    }
  }, []);
  console.log('H2');
  return (
    <ListContext.Provider value={{ list, setList }}>
      <div className="relative h-full w-[390px] sm:w-full md:w-[600px] lg:w-[800px]">
        <div className="flex justify-between">
          <div className="lg:mt-[15px] md:mt-[15px] w-[25%] sm:w-[25%] lg:w-[17%] md:w-[17%] p-[16px]">
            <img
              src="/assets/images/logo.png"
              alt="Listiro"
              className="w-[75px]"
            />
          </div>
          <div className=" lg:hidden md:hidden sm:block sm:w-[50%] h-[22px] mt-[15px] text-sm text-center">
            <a
              href={`/profile/${profileName}`}
              className="text-[#02CDB3] hover:underline"
            >
              { !avatar && (
                <img
                  width="20"
                  alt="user avatar"
                  className="inline pr-[5px]"
                  src="/assets/images/avatar.png"
                />
              )}
              { avatar && (
                <img
                  width="20"
                  alt="user avatar"
                  className="border border-[#444444] inline mr-[5px] rounded-[50%] object-cover w-[30px] h-[30px]"
                  src={`${avatar}`}
                />
              )}
              @{profileName}
            </a>
          </div>
          <div className="w-[25%] sm:w-[25%] lg:w-[83%] md:w-[83%]">
            <Menu dim={true} user={user} />
          </div>
        </div>
        <div className="hidden md:block lg:block w-full mt-[20px] text-center">
          <a
            href={`/profile/${profileName}`}
            className="text-sm text-[#02CDB3] hover:underline"
          >
            { !avatar && (
                <img
                  width="20"
                  alt="user avatar"
                  className="inline pr-[5px]"
                  src="/assets/images/avatar.png"
                />
              )}
              { avatar && (
                <img
                  alt="user avatar"
                  className="border border-[#444444] inline mr-[5px] rounded-[50%] object-cover w-[30px] h-[30px]"
                  src={`${avatar}`}
                />
              )}
            @{profileName}
          </a>
        </div>
        {props.list && <ListIntro data={list} />}
        {props.list && (
          <div
            style={{
              background:
                'radial-gradient(91.89% 91.89% at 86.39% 8.11%, #5800C1 0%, #1A022B 100%)',
            }}
            className="pb-[100px] pt-[0px] h-full px-[12px] mx-auto mt-[10px] bg-gradient-to-br from-purple-600 to-purple-800 rounded-tl-[15px] rounded-tr-[15px]"
          >
            {children}
          </div>
        )} 
        {!props.list && (
          <div
            style={{
              background:
                'radial-gradient(91.89% 91.89% at 86.39% 8.11%, #5800C1 0%, #1A022B 100%)',
            }}
            className="pb-[100px] pt-[42px] h-full px-[12px] mx-auto mt-[42px] bg-gradient-to-br from-purple-600 to-purple-800 rounded-tl-[15px] rounded-tr-[15px]"
          >
            {children}
          </div>
        )}
        <NotificationProvider>
          <BottomMenuBar create={props.create} showAddListIcon={true} />
        </NotificationProvider>
      </div>
    </ListContext.Provider>
  );
}

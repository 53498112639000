import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { NotificationContext } from '../Utils/contexts/NotificationContext';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const Logout = () => {
  const navigate = useNavigate();
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => { 
    const token = localStorage.getItem('jwtToken');
    if(token === null) {
      navigate("/login");
    }
    api
      .post('/api/auth/logout', null, {
        headers: { Authorization: `${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('user');
          navigate("/login");
        } else {
          showNotification("Something went wrong", "error");
        }
      })
      .catch((err) => {
        showNotification("Something went wrong", "error");
      });

    // If you need to clean up after the effect, you can return a function from the effect
    return () => {
      console.log('Component will unmount!');
    };
  }, [navigate]);

  return (
    <>
      <div>
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Logged Out
        </h2>
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8">
            
          </div>
        </div>
      </div>
    </>
  );
};

//@TODO download external images instead of just linking them
//@TODO share list
//@TODO why are all xhr requests doubled?
//@TODO fix date width in list intro
import { useContext } from 'react';
import { ListContext } from '../Utils/contexts/ListContext';
import { ReactComponent as GiftIcon } from '../Utils/ui-components/Icons/Gift.svg';
import { ReactComponent as CalendarIcon } from '../Utils/ui-components/Icons/Calendar.svg';
import { ReactComponent as CakeIcon } from '../Utils/ui-components/Icons/Cake.svg';
import { ReactComponent as PinIcon } from '../Utils/ui-components/Icons/Pin.svg';
import { ShareButton } from '../Utils/ShareButton';
import { formatDate } from '../../Utils';
import { useParams } from 'react-router-dom';

export const ListIntro = (props) => {
  const { list } = useContext(ListContext);
  const params = useParams();
  
  return (
    <div className="pt-[15px] px-[15px] mt-[5px] relative pb-0">
      <h2 className="underline decoration-[#02CDB2] decoration-3 underline-offset-8 tracking-wider text-left text-2xl font-sans font-bold tracking-tight text-white">
        {list.name}
      </h2>
      <div className="mt-[25px] w-[92%] flex flex-row max-w-[350px]">
        {list.eventDatetime && (
          <div className="flex stroke-white text-xsm font-light text-white mr-[10px]">
            <div>
              <CalendarIcon />
            </div>
            <div className="text-xs ml-[4px]">{formatDate(list.eventDatetime)}</div>
          </div>
        )}
        <div className="flex stroke-white text-xsm font-light text-white  mr-[10px]">
          <div>
            <GiftIcon />
          </div>
          <div className="ml-[4px] text-xs">
            {list.itemNo} {list.itemNo > 1 && `dorinte`}
            {list.itemNo === 1 && `dorinta`}
          </div>
        </div>
        {list.eventType && (
          <div className="flex stroke-white text-xsm font-light text-white  mr-[10px]">
            <div>
              <CakeIcon />
            </div>
            <div className="ml-[4px] capitalize text-xs">{list.eventType}</div>
          </div>
        )}
      </div>
      <div className="mt-[5px] w-[92%] grid grid-cols-4 max-w-[350px]">
        {list.eventLocation && (
          <div className="mt-[15px] col-span-4 flex stroke-[#02CCB5] text-sm font-light text-[#02CCB5]">
            <div>
              <PinIcon />
            </div>
            <div className="ml-[4px] capitalize">
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                  list.eventLocation
                )}`}
                target="_blank"
              >
                {list.eventLocation}
              </a>
            </div>
          </div>
        )}
      </div>
      <div className='absolute bottom-[15px] right-[15px]'>
        <ShareButton
          url={window.location.href}
          title={`Profilul lui ${params.profile}`}
          text="Listi.ro - listele tale de dorinte"
        />
        
      </div>
    </div>
  );
};

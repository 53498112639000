import { orangeGradient } from '../../../Utils/constants';
import React, { useState } from 'react';

export const TextInput = ({ children, ...props }) => {
  const type = props.type ? props.type : 'text';
  const required = props.required ? props.required : false;
  const style = props.style || {};
  const [isTouched, setIsTouched] = useState(false);
  //console.log('IF INPUT', props.errors,props.name, );
  if(isTouched && props.errors && props.errors.find(item => item.field === props.name)) {
    style['border'] = '3px solid red'
  } 
  
  return (
    <div
      style={{
        marginTop: `${props.mt ? props.mt : 0}px`,
      }}
    >
      <label className="text-white ml-[18px]" htmlFor={props.name}>
        {props.label}
      </label>
      <div
        className="relative w-full rounded-[25px] bg-black h-[42px] p-[2px] mt-[5px]"
        style={{
          background: orangeGradient,
        }}
      >
        <div className="absolute left-[15px] top-[10px]">{children}</div>
        {props.extra && (
          <div className="absolute right-[15px] top-[4px]">{props.extra}</div>
        )}

        <input
          data-testid={props.dataTestId}
          style={style}
          type={type}
          name={props.name}
          placeholder={props.placeholder}
          className="block pl-[42px] w-full bg-white rounded-[23px] h-[38px] py-1.5 text-[#8301E4] ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={props.value}
          onChange={e => {
            setIsTouched(true);
            props.onChange(e);
          }}
          id={props.customClass}
          required={required}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
        />

        {isTouched && props.errors && props.errors.find(item => item.field === props.name) && (
          <div className="text-[#DB606E] text-[12px] mt-[5px] mb-[10px] pl-[15px]">{props.errors.find(item => item.field === props.name).message}</div>
        )}

      </div>
    </div>
  );
};

import { ReactComponent as ReserveButtonSVG } from './ui-components/Icons/ReserveBig.svg';
import { ReactComponent as CancelReserveButtonSVG } from './ui-components/Icons/AnuleazaRezervarea.svg';
import { ReactComponent as ReserveButtonSmallSVG } from './ui-components/Icons/ReserveSmall.svg';
import { ReactComponent as CancelReserveButtonSmallSVG } from './ui-components/Icons/AnuleazaRezervareaSmall.svg';

export const ReserveButton = props => {
    const size = props.size || 'big';

    if(!props.reserved) {
        return (
            size === 'big' ? <ReserveButtonSVG data-testid="reserve-button-big" onClick={props.onClick} /> : <ReserveButtonSmallSVG  data-testid="reserve-button-small" onClick={props.onClick} />
        );
    } else {
        if(props.myReservation) {
            return (
                size === 'big' ? <CancelReserveButtonSVG  data-testid="cancel-reserve-button-big" onClick={props.onClick} /> : <CancelReserveButtonSmallSVG  data-testid="cancel-reserve-button-small" onClick={props.onClick} />
            );
        } else {
            return (<></>);
        }
    }
}
import { ReactComponent as MenuIcon } from '../Utils/ui-components/Icons/Menu.svg';
import { ReactComponent as XIcon } from '../Utils/ui-components/Icons/X.svg';
import {useEscapeKey} from './hooks/useEscapeKey';
import { Link } from 'react-router-dom';
import { Spacer } from './ui-components/Spacer';
import { orangeGradient } from '../../Utils/constants';
import { useState } from 'react';

export const Menu = (props) => {
  const [menuStatus, setMenuStatus] = useState(false);
  const switchMenu = () => {
    setMenuStatus(!menuStatus);
  };

  useEscapeKey(() => {
    setMenuStatus(false);
  });

  return (
    <>
      <div className="relative  w-full ">
        <div className="block absolute top-[16px] right-[16px] lg:hidden md:hidden sm:block">
          {!props.dim && (
            <div
              style={{
                background:
                  'radial-gradient(70% 70% at 50% 50%, #BB00FF 30%, rgba(0, 0, 0, 0) 100%)',
                boxShadow: '0px 0px 14px 6px #8200B1',
              }}
              className="border-white border-solid border-2 rounded-[25px] bg-black bg-opacity-90"
              onClick={switchMenu}
            >
              <MenuIcon />
            </div>
          )}
          {props.dim && (
            <div className="bg-black bg-opacity-90" onClick={switchMenu}>
              <MenuIcon />
            </div>
          )}

          <div className={menuStatus ? 'open' : 'closed'} id="menuContainer">
            <div
              className="w-full py-[15px] px-[15px] relative"
              onClick={switchMenu}
            >
              <div
                style={{
                  background:
                    'radial-gradient(70% 70% at 50% 50%, #BB00FF 30%, rgba(0, 0, 0, 0) 100%)',
                  boxShadow: '0px 0px 14px 6px #8200B1',
                }}
                className="absolute top-[16px] right-[16px] border-white border-solid border-2 rounded-[25px] bg-black bg-opacity-90"
                onClick={switchMenu}
              >
                <XIcon />
              </div>
              {!props.user && (
                <>
                  <div className="ml-[20px] mt-[100px]">
                    <Link
                      className="text-[#02CDB3] hover:underline text-2xl"
                      to="/login"
                    >
                      Login
                    </Link>
                  </div>
                  <Spacer noBlend={true} mb="30" mt="30" />
                  <div className="ml-[20px]">
                    <Link
                      className="text-[#02CDB3] hover:underline text-2xl"
                      to="/register"
                    >
                      Fa-ti cont
                    </Link>
                  </div>
                </>
              )}
              {props.user && (
                <>
                  <div className="ml-[20px]  mt-[100px]">
                    <Link
                      className="text-[#02CDB3] hover:underline text-2xl"
                      to="/lists/create"
                    >
                      Creaza o lista
                    </Link>
                  </div>
                  <div className="ml-[20px]">
                    <Link
                      className="text-[#02CDB3] hover:underline text-2xl"
                      to="/lists"
                    >
                      Listele tale
                    </Link>
                  </div>
                  <div className="ml-[20px]">
                    <Link
                      className="text-[#02CDB3] hover:underline text-2xl"
                      to="/user-profile"
                    >
                      Contul tau
                    </Link>
                  </div>
                  <Spacer noBlend={true} mb="30" mt="30" />
                  <div className="ml-[20px]">
                    <Link
                      className="text-[#02CDB3] hover:underline text-2xl"
                      to="/logout"
                    >
                      Logout
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ background: orangeGradient }}
          className="hidden absolute  top-[20px] sm:hidden md:block lg:block rounded-[25px] bg-[#5000AF] w-full h-[40px]"
        >
          <div className="w-full grid grid-cols-12 gap-1">
          {!props.user && (
            <>
              <div className="text-white col-span-2 mt-[5px] pt-[3px] px-2 bg-white/20 rounded-[20px] h-[90%] text-center ml-[10px]">
                <Link to="/login">Login</Link>
              </div>
              <div className="text-white col-span-3 mt-[5px] pt-[3px] px-2 bg-white/20 rounded-[20px] h-[90%] text-center ml-[10px]">
                <Link to="/register">Fa-ti cont</Link>
              </div>
            </>
          )}
          {props.user && (
            <>
              <div className="text-white col-span-3 mt-[5px] pt-[3px] px-2 bg-white/20 rounded-[20px] h-[90%] text-center ml-[10px]">
                <Link to="/lists/create">+ Creaza o lista</Link>
              </div>
              <div className="text-white col-span-3 mt-[5px] pt-[3px] px-2 bg-white/20 rounded-[20px] h-[90%] text-center ml-[10px]">
                <Link to="/lists">Listele tale</Link>
              </div>
              <div className="text-white col-span-3 mt-[5px] pt-[3px] px-2 bg-white/20 rounded-[20px] h-[90%] text-center ml-[10px]">
                <Link to="/user-profile">Contul tau</Link>
              </div>
              <div className="text-white col-span-3 mt-[5px] pt-[3px] px-2 bg-white/20 rounded-[20px] h-[90%] text-center ml-[10px]">
                <Link to="/logout">Logout</Link>
              </div>
            </>
          )}
          </div>
        </div>
      </div>
    </>
  );
};

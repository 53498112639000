import { useEffect, useState } from 'react';
import {
  NOTIFICATION_FADE_INTERVAL,
  NOTIFICATION_BG_ERROR,
  NOTIFICATION_BG_SUCCESS,
} from '../../../Utils/constants';

export const Notification = (props) => {
  const bgColor =
    props.type === 'error' ? NOTIFICATION_BG_ERROR : NOTIFICATION_BG_SUCCESS;
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    if (props.isOpen) {
      setIsOpen(true);
      setTimeout(() => {
        setIsOpen(false);
        props.setIsOpen(false);
      }, NOTIFICATION_FADE_INTERVAL);
    }
    setIsOpen(props.isOpen);
  }, [props.isOpen]);
  
  return (
    <div
      id="notificationContainer"
      data-testid="notificationContainer"
      style={{ background: bgColor }}
      className={isOpen ? 'open' : 'closed'}
    >
      {props.message || props.errorMessage}
    </div>
  );
};
